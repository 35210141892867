//
// This file was generated by the Analytics code generator.
// Do not hand-edit this file
//

export enum AnalyticsEvents {
    Accounts_AddLoanAccountDetails = "Add Loan Account Details",
    Accounts_ClickedAddAccount = "Clicked Add Account",
    Accounts_ClickedAddConnection = "Clicked Add Connection",
    Accounts_ClickedExistingConnection = "Clicked Existing Connection",
    Accounts_ClickedSectionHeader = "Clicked Section Header",
    Accounts_ClickedViewAllTransactions = "Clicked View All Transactions",
    Accounts_ClickedViewMyBudget = "Clicked View My Budget",
    Accounts_ClickedViewOverspentCategories = "Clicked View Overspent Categories",
    Accounts_ClickedViewRemainingTransactions = "Clicked View Remaining Transactions",
    Accounts_ClosedAccount = "Closed Account",
    Accounts_CombinedPayees = "Combined Payees",
    Accounts_CopiedPaydownAnalysisAmountToBudget = "Copied Paydown Analysis Amount to Budget",
    Accounts_CreatedAccount = "Created Account",
    Accounts_CreatedPayeeRenamingRule = "Created Payee Renaming Rule",
    Accounts_DeletedBankAccount = "Deleted Bank Account",
    Accounts_DeletedLoanActivity = "Deleted Loan Activity",
    Accounts_EditedLoanActivity = "Edited Loan Activity",
    Accounts_EncounteredDirectImportError = "Encountered Direct Import Error",
    Accounts_FilteredAccount = "Filtered Account",
    Accounts_FinishedReconciliation = "Finished Reconciliation",
    Accounts_HidRunningBalance = "Hid Running Balance",
    Accounts_PairedAccountWithExistingCategory = "Paired Account with Existing Category",
    Accounts_PairedAccountWithNewCategory = "Paired Account with New Category",
    Accounts_RecordedLoanActivity = "Recorded Loan Activity",
    Accounts_SearchedAccount = "Searched Account",
    Accounts_SelectedAccountConnectionType = "Selected Account Connection Type",
    Accounts_SelectedInstitution = "Selected Institution",
    Accounts_ShowedRunningBalance = "Showed Running Balance",
    Accounts_SkippedCategoryPairing = "Skipped Category Pairing",
    Accounts_StartedDebtAccountMigration = "Started Debt Account Migration",
    Accounts_StartedDebtStartingBalanceErrorFix = "Started Debt Starting Balance Error Fix",
    Accounts_StartedReconciliation = "Started Reconciliation",
    Accounts_UnpairedAccountFromCategory = "Unpaired Account from Category",
    Accounts_UpdateBalance = "Update Balance",
    Budget_AdjustedAutoAssignAmount = "Adjusted Auto-Assign Amount",
    Budget_AssignedMoney = "Assigned Money",
    Budget_CanceledTargetSnooze = "Canceled Target Snooze",
    Budget_ClickedAutoAssign = "Clicked Auto Assign",
    Budget_ClickedCategoryNotes = "Clicked Category Notes",
    Budget_ClickedCategoryRecentMoves = "Clicked Category Recent Moves",
    Budget_ClickedCollapseAllGroups = "Clicked Collapse All Groups",
    Budget_ClickedCoverCreditOverspending = "Clicked Cover Credit Overspending",
    Budget_ClickedCreateDebtPayoffTarget = "Clicked Create Debt Payoff Target",
    Budget_ClickedExpandAllGroups = "Clicked Expand All Groups",
    Budget_ClickedMonthNotes = "Clicked Month Notes",
    Budget_ClickedPairCategoryWithLoan = "Clicked Pair Category with Loan",
    Budget_ClickedQuickBudget = "Clicked Quick Budget",
    Budget_ClickedQuickBudgetFirstTime = "Clicked Quick Budget First Time",
    Budget_ClickedViewUncategorizedCreditTransactions = "Clicked View Uncategorized Credit Transactions",
    Budget_ClosedGetAMonthAheadVideo = "Closed Get A Month Ahead Video",
    Budget_CompletedAddExpenses = "Completed Add Expenses",
    Budget_CoveredOverspending = "Covered Overspending",
    Budget_CreatedCategory = "Created Category",
    Budget_CreatedCategoryGroup = "Created Category Group",
    Budget_CreatedFocusedView = "Created Focused View",
    Budget_CreatedGoal = "Created Goal",
    Budget_DeletedCategory = "Deleted Category",
    Budget_DeletedCategoryGroup = "Deleted Category Group",
    Budget_DeletedFocusedView = "Deleted Focused View",
    Budget_DeletedGoal = "Deleted Goal",
    Budget_DismissedGetAMonthAheadHelpPrompt = "Dismissed Get A Month Ahead Help Prompt",
    Budget_EditedDebtTarget = "Edited Debt Target",
    Budget_EnteredAutoAssignPreview = "Entered Auto-Assign Preview",
    Budget_EnteredExpectedIncome = "Entered Expected Income",
    Budget_ExpandedCreditCardPaymentDetail = "Expanded Credit Card Payment Detail",
    Budget_FilteredRecentMoves = "Filtered Recent Moves",
    Budget_FinishedMoveMoneyFromTbb = "Finished Move Money from TBB",
    Budget_FixedOverbudgeting = "Fixed Overbudgeting",
    Budget_HidCategory = "Hid Category",
    Budget_HidCategoryGroup = "Hid Category Group",
    Budget_ImportedCategoryTemplate = "Imported Category Template",
    Budget_MovedMoney = "Moved Money",
    Budget_NavigatedToFutureMonth = "Navigated To Future Month",
    Budget_OpenedRecentMoves = "Opened Recent Moves",
    Budget_Overbudgeted = "Overbudgeted",
    Budget_PairedCategoryWithLoan = "Paired Category with Loan",
    Budget_ReorderedFocusedViews = "Reordered Focused Views",
    Budget_SavedAssignments = "Saved Assignments",
    Budget_SelectedFocusedView = "Selected Focused View",
    Budget_ShowedCategory = "Showed Category",
    Budget_ShowedCategoryGroup = "Showed Category Group",
    Budget_SnoozedTarget = "Snoozed Target",
    Budget_StartedCreateTarget = "Started Create Target",
    Budget_StartedGetAMonthAheadVideo = "Started Get A Month Ahead Video",
    Budget_StartedRecordPaymentFromInspector = "Started Record Payment from Inspector",
    Budget_TappedFutureMonthButton = "Tapped Future Month Button",
    Budget_ToggledProgressBars = "Toggled Progress Bars",
    Budget_UsedAssignKeypadAccessory = "Used Assign Keypad Accessory",
    Budget_ViewedCategoryMoves = "Viewed Category Moves",
    Budget_ViewedCreditCardPaymentInspector = "Viewed Credit Card Payment Inspector",
    Budget_ViewedNoExpensesAddedError = "Viewed No Expenses Added Error",
    Budget_ViewedToBeBudgetedBreakdown = "Viewed To be Budgeted Breakdown",
    General_AgedMoneyFirstTime = "Aged Money First Time",
    General_ClickedCopyGroupInvite = "Clicked Copy Group Invite",
    General_ClickedMultiSelectCheckbox = "Clicked Multi-Select Checkbox",
    General_ClickedReferralProgramButton = "Clicked Referral Program Button",
    General_CreatedNewBudget = "Created New Budget",
    General_CreatedNewGroupInvite = "Created New Group Invite",
    General_EnteredOverdraftState = "Entered Overdraft State",
    General_ExportedBudget = "Exported Budget",
    General_ExportedReport = "Exported Report",
    General_ExportedTransactions = "Exported Transactions",
    General_LoggedIn = "Logged In",
    General_LoggedOut = "Logged Out",
    General_MadeFreshStart = "Made Fresh Start",
    General_OpenedBudget = "Opened Budget",
    General_RemovedGroupMember = "Removed Group Member",
    Help_ViewedGroupResources = "Viewed Group Resources",
    Import_ClosedPlaidWidget = "Closed Plaid Widget",
    Import_ImportedFbiTransactions = "Imported FBI Transactions",
    Import_ViewedPlaidWidget = "Viewed Plaid Widget",
    Onboarding_AdvancedCategoryWizardStep = "Advanced Category Wizard Step",
    Onboarding_AdvancedMintImporterStep = "Advanced Mint Importer Step",
    Onboarding_AssignedSavingsToExpenses = "Assigned Savings to Expenses",
    Onboarding_ClickedMigrateFrom = "Clicked Migrate From",
    Onboarding_ClosedIntro = "Closed Intro",
    Onboarding_ClosedOutro = "Closed Outro",
    Onboarding_CompletedAddAccountStep = "Completed Add Account Step",
    Onboarding_CreatedSavingsCategory = "Created Savings Category",
    Onboarding_DoneEstimatingExpenses = "Done Estimating Expenses",
    Onboarding_LaunchedCategoryWizard = "Launched Category Wizard",
    Onboarding_OpenedOutro = "Opened Outro",
    Onboarding_RatedFeeling = "Rated Feeling",
    Onboarding_RestartedCategoryWizard = "Restarted Category Wizard",
    Onboarding_ReturnedToSettingTargetsOnboarding = "Returned to Setting Targets Onboarding",
    Onboarding_SelectedSpendingRecipients = "Selected Spending Recipients",
    Onboarding_SkippedAddingOnBudgetAccount = "Skipped Adding On-Budget Account",
    Onboarding_SkippedOnboarding = "Skipped Onboarding",
    Onboarding_SkippedSettingTargetsOnboarding = "Skipped Setting Targets Onboarding",
    Onboarding_StartedAddAccountStep = "Started Add Account Step",
    Onboarding_StartedAssignMoneyStep = "Started Assign Money Step",
    Onboarding_StartedWebFtueOnboarding = "Started Web FTUE Onboarding",
    PageView_ViewedActivity = "Viewed Activity",
    PageView_ViewedAgeOfMoneyReport = "Viewed Age of Money Report",
    PageView_ViewedAllAccounts = "Viewed All Accounts",
    PageView_ViewedCategoryActivity = "Viewed Category Activity",
    PageView_ViewedFamilySettings = "Viewed Family Settings",
    PageView_ViewedGroupDashboard = "Viewed Group Dashboard",
    PageView_ViewedGroupMembers = "Viewed Group Members",
    PageView_ViewedGroupSubscription = "Viewed Group Subscription",
    PageView_ViewedIncomeVExpenseReport = "Viewed Income v Expense Report",
    PageView_ViewedManagePayees = "Viewed Manage Payees",
    PageView_ViewedNetWorthReport = "Viewed Net Worth Report",
    PageView_ViewedReconciliationPopover = "Viewed Reconciliation Popover",
    PageView_ViewedReferralProgram = "Viewed Referral Program",
    PageView_ViewedReflectTab = "Viewed Reflect Tab",
    PageView_ViewedSettings = "Viewed Settings",
    PageView_ViewedSpendingBreakdown = "Viewed Spending Breakdown",
    PageView_ViewedSpendingReport = "Viewed Spending Report",
    PageView_ViewedSubscribe = "Viewed Subscribe",
    Reports_FilteredReport = "Filtered Report",
    SignUp_ClickedSignUp = "Clicked Sign Up",
    SignUp_CompletedSignUp = "Completed Sign Up",
    SignUp_CreatedUser = "Created User",
    Social_SentReferralInvite = "Sent Referral Invite",
    Subscription_GiftedSubscription = "Gifted Subscription",
    Subscription_SelectedCancellationReason = "Selected Cancellation Reason",
    Subscription_SelectedPayUpFront = "Selected Pay Up Front",
    Subscription_StartedTrial = "Started Trial",
    Subscription_ViewedExpirationMessage = "Viewed Expiration Message",
    Support_ClickedChatWidget = "Clicked Chat Widget",
    Transaction_ClickedBigBoxLink = "Clicked Big Box Link",
    Transaction_ClickedSplitTransaction = "Clicked Split Transaction",
    Transaction_CustomizedFlagNames = "Customized Flag Names",
    Transaction_DeletedTransaction = "Deleted Transaction",
    Transaction_MatchedTransaction = "Matched Transaction",
    Transaction_RejectedTransaction = "Rejected Transaction",
    Transaction_SearchedCategory = "Searched Category",
    Transaction_SetAccount = "Set Account",
    Transaction_SetFlag = "Set Flag",
    Transaction_UnmatchedTransaction = "Unmatched Transaction",
    Transaction_UsedEnterNow = "Used Enter Now",
}

export enum AnalyticsProperties {
    AccountsFilter = "Accounts Filter",
    AccountType = "Account Type",
    Action = "Action",
    ActivityType = "Activity Type",
    AdjustmentAmount = "Adjustment Amount",
    AdjustmentTransactionCreated = "Adjustment Transaction Created",
    AssignmentMonth = "Assignment Month",
    AutoAssignLocation = "Auto-Assign Location",
    CategoriesFilter = "Categories Filter",
    CategoryTemplate = "Category Template",
    CategoryWizardResponse = "Category Wizard Response",
    CloseMethod = "Close Method",
    ConnectionType = "Connection Type",
    CorrectedMatch = "Corrected Match",
    Count = "Count",
    Coverage = "Coverage",
    CreditCardPaymentStatus = "Credit Card Payment Status",
    Currency = "Currency",
    CustomFlagName = "Custom Flag Name",
    DateFilter = "Date Filter",
    DebtTargetInputType = "Debt Target Input Type",
    DeletedTransactionMethod = "Deleted Transaction Method",
    DestinationUrl = "Destination URL",
    Detail = "Detail",
    DirectImportCurrentBalanceMatch = "Direct Import Current Balance Match",
    DirectImportProvider = "Direct Import Provider",
    DirectImportProviderError = "Direct Import Provider Error",
    Escrow = "Escrow",
    ExitSurveyOption = "Exit Survey Option",
    FbiError = "FBI Error",
    FeelingRate = "Feeling Rate",
    FileType = "File Type",
    FinancialInstitution = "Financial Institution",
    FlagsWithCustomNames = "Flags with Custom Names",
    FromCategoryType = "From Category Type",
    FromTbbOrToTbb = "From TBB or to TBB",
    GoalType = "Goal Type",
    ImportedAccountType = "Imported Account Type",
    ImportedSelectCategories = "Imported Select Categories",
    ImporterStep = "Importer Step",
    Interest = "Interest",
    Location = "Location",
    LogInMethod = "Log In Method",
    MatchedTransactionMethod = "Matched Transaction Method",
    MigrationPath = "Migration Path",
    MigrationSource = "Migration Source",
    MonthDelta = "Month Delta",
    MovesTypeFilter = "Moves Type Filter",
    MultipleWarnings = "Multiple Warnings",
    NavigationSource = "Navigation Source",
    NewTarget = "New Target",
    NoteInitialState = "Note Initial State",
    On_off = "On/Off",
    OnboardingPath = "Onboarding Path",
    OpenConversationCount = "Open Conversation Count",
    OpenConversationsIds = "Open Conversations IDs",
    OutroState = "Outro State",
    PageName = "Page Name",
    PairedCategory = "Paired Category",
    PaydownType = "Paydown Type",
    PendingTransactionsFilter = "Pending Transactions Filter",
    PercentageOfCategoriesThatHaveTargets = "Percentage of categories that have targets",
    PercentageOfCategoriesWhichWereAssignedTo = "Percentage of categories which were assigned to",
    PercentageOfRemainingInRta = "Percentage of remaining in RTA",
    PlaidAccountsLinkedCount = "Plaid Accounts Linked Count",
    PlaidChosePreselectedInstitution = "Plaid Chose Preselected Institution",
    PlaidExitReasonSummary = "Plaid Exit Reason Summary",
    PlaidPhoneField = "Plaid Phone Field",
    PlaidSavedAccountsScreen = "Plaid Saved Accounts Screen",
    PlaidSavedInstitutionsScreen = "Plaid Saved Institutions Screen",
    PlaidWidgetOpenDuration = "Plaid Widget Open Duration",
    ProcessingState = "Processing State",
    QuickBudgetCount = "Quick Budget Count",
    QuickBudgetMethod = "Quick Budget Method",
    QuickBudgetType = "Quick Budget Type",
    ReconciledTransactionFilter = "Reconciled Transaction Filter",
    ReconciliationStep = "Reconciliation Step",
    ReferralMethod = "Referral Method",
    ReflectionPath = "Reflection Path",
    RejectedTransactionMethod = "Rejected Transaction Method",
    RenamingPath = "Renaming Path",
    ReportType = "Report Type",
    Resource = "Resource",
    ResponsePosition = "Response Position",
    RtaState = "RTA State",
    ScheduledTransactionsFilter = "Scheduled Transactions Filter",
    SearchedAccount = "Searched Account",
    SearchedApproved = "Searched Approved",
    SearchedCategory = "Searched Category",
    SearchedCleared = "Searched Cleared",
    SearchedDate = "Searched Date",
    SearchedFlag = "Searched Flag",
    SearchedInflow = "Searched Inflow",
    SearchedMemo = "Searched Memo",
    SearchedNeedsCategory = "Searched Needs Category",
    SearchedOutflow = "Searched Outflow",
    SearchedPayee = "Searched Payee",
    SearchedReconciled = "Searched Reconciled",
    SearchedUnapproved = "Searched Unapproved",
    SearchedUncleared = "Searched Uncleared",
    SearchedUnreconciled = "Searched Unreconciled",
    SectionHeaderEndState = "Section Header End State",
    SectionHeaderType = "Section Header Type",
    SetAccountMethod = "Set Account Method",
    SetFlagMethod = "Set Flag Method",
    SignUpMethod = "Sign Up Method",
    SignUpSource = "Sign Up Source",
    SpendingRecipientType = "Spending Recipient Type",
    StartedReconciliationMethod = "Started Reconciliation Method",
    StartSource = "Start Source",
    Status = "Status",
    StopImportingTransactionsSelection = "Stop Importing Transactions Selection",
    SubscriptionPlan = "Subscription Plan",
    SubscriptionProvider = "Subscription Provider",
    SufficientIncome = "Sufficient Income",
    TargetSnoozeLocation = "Target Snooze Location",
    ToCategoryType = "To Category Type",
    TransactionContextMenuSelection = "Transaction Context Menu Selection",
    TransactionCount = "Transaction Count",
    TransactionState = "Transaction State",
    TransactionsType = "Transactions Type",
    UnmatchedTransactionMethod = "Unmatched Transaction Method",
    UsedEnterNowMethod = "Used Enter Now Method",
    UserJourneyState = "User Journey State",
    UtmCampaign = "UTM Campaign",
    UtmContent = "UTM Content",
    UtmMedium = "UTM Medium",
    UtmSource = "UTM Source",
    ViewedActivityMethod = "Viewed Activity Method",
    ViewType = "View type",
    WidgetReason = "Widget Reason",
    WidgetSelected = "Widget Selected",
    WizardStep = "Wizard Step",
    ZeroBalance = "Zero Balance",
}

export enum AnalyticsPropertyValues {
    Account = "Account",
    AccountEditor = "Account Editor",
    AccountList = "Account List",
    AccountWidget = "Account Widget",
    ActionBar = "Action Bar",
    ActivityPopover = "Activity Popover",
    AfterWizard = "After Wizard",
    All = "All",
    AllAccounts = "All Accounts",
    AllDates = "All Dates",
    Android = "Android",
    Annual = "Annual",
    Apple = "Apple",
    ApprovalPopover = "Approval Popover",
    ApproveOption = "Approve Option",
    Asset = "Asset",
    Assigned = "Assigned",
    AssignedInFutureMonths = "Assigned in Future Months",
    AssignMode = "Assign Mode",
    Attempted = "Attempted",
    AutoLoan = "Auto Loan",
    AutoPresented = "Auto Presented",
    AvailableForPayment = "Available for Payment",
    BalanceMatch = "Balance Match",
    BalanceMismatch = "Balance Mismatch",
    BalanceNotAvailable = "Balance Not Available",
    BalanceOutOfDate = "Balance Out of Date",
    Banner = "Banner",
    Budget = "Budget",
    BudgetHeader = "Budget Header",
    BudgetMenu = "Budget Menu",
    BudgetRow = "Budget Row",
    BudgetRowContextMenu = "Budget Row Context Menu",
    BulkEdit = "Bulk Edit",
    Cash = "Cash",
    Categories = "Categories",
    CategorizeOption = "Categorize Option",
    CategoryDetails = "Category Details",
    CategoryGroup = "Category Group",
    CategorySheet = "Category Sheet",
    Changed = "Changed",
    Charge = "Charge",
    Checking = "Checking",
    ClearUnclearOption = "Clear Unclear Option",
    ClickedToView = "Clicked To View",
    ClosedMintMigration = "Closed Mint Migration",
    Collapsed = "Collapsed",
    Complete = "Complete",
    CompletedMintVideoStep = "Completed Mint Video Step",
    CompletedWizard = "Completed Wizard",
    Confident = "confident",
    ConnectionInErrorState = "Connection in Error State",
    Continue = "Continue",
    CostToBeMe = "Cost To Be Me",
    Credit = "Credit",
    CreditCard = "Credit Card",
    CreditOverspending = "Credit Overspending",
    Csv = "CSV",
    CurrentBalance = "Current Balance",
    CurrentMonth = "Current Month",
    Custom = "Custom",
    DebtInterstitial = "Debt Interstitial",
    DebtQuestion = "Debt Question",
    DeleteOption = "Delete Option",
    DeselectAll = "Deselect All",
    Dismiss = "Dismiss",
    Dropdown = "Dropdown",
    DueDate = "Due Date",
    DuplicateOption = "Duplicate Option",
    Edited = "Edited",
    EditImportedTransaction = "Edit Imported Transaction",
    EditMenu = "Edit Menu",
    EditPlan = "Edit Plan",
    EditTargetScreen = "Edit Target Screen",
    Email = "Email",
    Empty = "Empty",
    EnterNowOption = "Enter Now Option",
    Escrow = "Escrow",
    Estimated = "Estimated",
    EvergreenNavigationArrows = "Evergreen Navigation Arrows",
    Exact = "Exact",
    ExistingConnection = "Existing Connection",
    Expanded = "Expanded",
    False = "False",
    FeelingsInterstitial = "Feelings Interstitial",
    FeelingsQuestion = "Feelings Question",
    Filled = "Filled",
    FinalQuestion = "Final Question",
    FinancialWellnessPlan = "Financial Wellness Plan",
    FlagOption = "Flag Option",
    Forethought = "Forethought",
    ForethoughtDisabled = "Forethought Disabled",
    ForethoughtEnabled = "Forethought Enabled",
    FromTbb = "From TBB",
    FtueClassicEditMode = "FTUE Classic Edit Mode",
    FullBudget = "Full Budget",
    FullyOrOverFunded = "Fully Or Over Funded",
    FullyPaid = "Fully Paid",
    FutureMonth = "Future Month",
    Google = "Google",
    GoogleInAppPurchase = "Google In-App Purchase",
    HaveABalance = "Have a Balance",
    HaveABalanceByDate = "Have a Balance by Date",
    HidePendingTransactions = "Hide Pending Transactions",
    HideReconciledTransactions = "Hide Reconciled Transactions",
    HideScheduledTransactions = "Hide Scheduled Transactions",
    HomeQuestion = "Home Question",
    InAppPurchase = "In-App Purchase",
    IncomeVExpense = "Income v Expense",
    Inspector = "Inspector",
    InspirationalInterstitial = "Inspirational Interstitial",
    InstitutionList = "Institution List",
    InterestCharge = "Interest Charge",
    Ios = "Ios",
    KeyboardShortcut = "Keyboard Shortcut",
    Keypad = "Keypad",
    KeypadBanner = "Keypad Banner",
    Kids = "Kids",
    Kustomer = "Kustomer",
    LandingScreen = "Landing Screen",
    LastYear = "Last Year",
    Latest12Months = "Latest 12 Months",
    Latest3Months = "Latest 3 Months",
    Latest6Months = "Latest 6 Months",
    Liability = "Liability",
    LineOfCredit = "Line of Credit",
    Linked = "Linked",
    Loading = "Loading",
    LongPressCategoryOnBudgetScreen = "Long Press Category on Budget Screen",
    LongPressCategoryOnBudgetScreenIos = "Long press category on Budget screen (iOS)",
    ManagePayees = "Manage Payees",
    MatchDetails = "Match Details",
    Matched = "Matched",
    MatchingPopover = "Matching Popover",
    MatchUnmatchOption = "Match Unmatch Option",
    MedicalDebt = "Medical Debt",
    Menu = "Menu",
    Mint = "Mint",
    Monthly = "Monthly",
    MonthlyDebtPaymentPaired = "Monthly Debt Payment Paired",
    MonthlyDebtPaymentUnpaired = "Monthly Debt Payment Unpaired",
    MonthlyPayment = "Monthly Payment",
    MonthPicker = "Month Picker",
    MoreMenu = "More Menu",
    MoreTransactions = "More Transactions",
    Mortgage = "Mortgage",
    MortgageQuestion = "Mortgage Question",
    Moved = "Moved",
    MoveMoneyPopover = "Move Money Popover",
    MoveOption = "Move Option",
    Multiple = "Multiple",
    Mx = "MX",
    MyPartner = "My Partner",
    MyPlan = "My Plan",
    Myself = "Myself",
    NameQuestion = "Name Question",
    Negative = "Negative",
    Neither = "Neither",
    NetWorth = "Net Worth",
    NewConnection = "New Connection",
    No = "No",
    None = "None",
    NotShown = "Not Shown",
    Ofx = "OFX",
    Onboarding = "Onboarding",
    OneTimePayment = "One Time Payment",
    OpenKustomerConversations = "Open Kustomer Conversations",
    OtherAdults = "Other Adults",
    OtherDebt = "Other Debt",
    Overspent = "Overspent",
    OverspentMoreTransactions = "Overspent & More Transactions",
    Partial = "Partial",
    PartnerInterstitial = "Partner Interstitial",
    PartnerQuestion = "Partner Question",
    PayeePopover = "Payee Popover",
    Payment = "Payment",
    PayOffBalanceByDate = "Pay Off Balance by Date",
    PayoffDate = "Payoff Date",
    PaySpecificAmountEachMonth = "Pay Specific Amount Each Month",
    Pending = "Pending",
    PersonalLoan = "Personal Loan",
    Pets = "Pets",
    Plaid = "Plaid",
    PlanSelectionScreen = "Plan Selection Screen",
    PopularInstitutions = "Popular Institutions",
    Positive = "Positive",
    PostedPending = "Posted Pending",
    PrioritiesInterstitial = "Priorities Interstitial",
    PriorMonth = "Prior Month",
    Qfx = "QFX",
    Qif = "QIF",
    ReadyToAssign = "Ready to Assign",
    RecentMoves = "Recent Moves",
    Reconciled = "Reconciled",
    Recurly = "Recurly",
    RefillUpToByDate = "Refill Up to by Date",
    RefillUpToCustomRepeat = "Refill Up to Custom Repeat",
    RefillUpToMonthly = "Refill Up to Monthly",
    RefillUpToWeekly = "Refill Up to Weekly",
    RefillUpToYearly = "Refill Up to Yearly",
    Refund = "Refund",
    RegisterActionBar = "Register Action Bar",
    RegularSpendQuestion = "Regular Spend Question",
    RejectOption = "Reject Option",
    RightClickMenu = "Right-Click Menu",
    Savings = "Savings",
    SavingsQuestion = "Savings Question",
    Scheduled = "Scheduled",
    ScheduledPending = "Scheduled Pending",
    SelectAll = "Select All",
    Selected = "Selected",
    SelectedOnboardingPath = "Selected Onboarding Path",
    SelfReportedSourceQuestion = "Self-Reported Source Question",
    SetAsideAnotherByDate = "Set Aside Another by Date",
    SetAsideAnotherCustomRepeat = "Set Aside Another Custom Repeat",
    SetAsideAnotherMonthly = "Set Aside Another Monthly",
    SetAsideAnotherWeekly = "Set Aside Another Weekly",
    SetAsideAnotherYearly = "Set Aside Another Yearly",
    Settings = "Settings",
    SettingsDropdown = "Settings Dropdown",
    SettingsMenu = "Settings Menu",
    SettingsPage = "Settings Page",
    ShowAll = "Show All",
    Shown = "Shown",
    ShownButUnwanted = "Shown But Unwanted",
    ShowNone = "Show None",
    ShowPendingTransactions = "Show Pending Transactions",
    ShowReconciledTransactions = "Show Reconciled Transactions",
    ShowScheduledTransactions = "Show Scheduled Transactions",
    ShowSelection = "Show Selection",
    Sidebar = "Sidebar",
    SignUp = "Sign-Up",
    Skip = "Skip",
    Skipped = "Skipped",
    SkippedAfterAttempt = "Skipped After Attempt",
    Spending = "Spending",
    SpendingBreakdown = "Spending Breakdown",
    SpendingReport = "Spending Report",
    SpendingTrends = "Spending Trends",
    Spotlight = "Spotlight",
    Stable = "stable",
    StartedTrial = "Started Trial",
    StartedWizard = "Started Wizard",
    Stressed = "stressed",
    StudentLoan = "Student Loan",
    SubscriptionsQuestion = "Subscriptions Question",
    Swipe = "Swipe",
    SwipeLeftIos = "Swipe left (iOS)",
    Target = "Target",
    Teens13 = "Teens (13+)",
    ThisMonth = "This Month",
    ThisYear = "This Year",
    ToggledOff = "Toggled Off",
    ToggledOn = "Toggled On",
    ToTbb = "To TBB",
    TransactionDetailsView = "Transaction Details View",
    TransactionRow = "Transaction Row",
    TransactionRowContextMenu = "Transaction Row Context Menu",
    TransportationQuestion = "Transportation Question",
    True = "True",
    TrueExpensesInterstitial = "True Expenses Interstitial",
    TrueExpensesQuestion = "True Expenses Question",
    Truelayer = "Truelayer",
    UncategorizedTransactions = "Uncategorized Transactions",
    Underfunded = "Underfunded",
    UnfundedPaymentMade = "Unfunded Payment Made",
    Unknown = "Unknown",
    Unlinked = "Unlinked",
    Unsure = "unsure",
    UploadedMintFile = "Uploaded Mint File",
    UserEntered = "User-Entered",
    Verified = "Verified",
    Web = "Web",
    Yes = "Yes",
    Zero = "Zero",
}

export enum AnalyticsUserPropertyValues {
    AddedDi = "Added DI",
    AddedUnlinkedAccount = "Added Unlinked Account",
    AndroidFontScale = "Android Font Scale",
    AndroidWebviewSupportsForceDark = "Android WebView Supports Force Dark",
    AndroidWebviewSupportsForceDarkStrategy = "Android WebView Supports Force Dark Strategy",
    AppInstallDate = "App Install Date",
    AppLock = "App Lock",
    BillingLocation = "Billing Location",
    Carrier = "carrier",
    City = "city",
    ClosedTrackBetaTester = "Closed-Track Beta Tester",
    CoachingTrialExtension = "Coaching Trial Extension",
    CollegeProgram = "College Program",
    CompanySize = "Company Size",
    Country = "country",
    CurrentSubscriptionCreatedDate = "Current Subscription Created Date",
    Dclid = "dclid",
    Device = "device",
    DifferentiateWithoutColor = "Differentiate Without Color",
    DirectImportInstitutions = "Direct Import Institutions",
    DirectImportProvider = "Direct Import Provider",
    DisableKeyboardShortcuts = "Disable Keyboard Shortcuts",
    Dma = "dma",
    DynamicTypeSize = "Dynamic Type Size",
    EarlyAccess = "Early Access",
    Email = "Email",
    FamilyId = "Family ID",
    FamilySeatsUsed = "Family Seats Used",
    FamilyUserRole = "Family User Role",
    Fbclid = "fbclid",
    FeatureFlags = "Feature Flags",
    FirstSubscriptionDate = "First Subscription Date",
    Gbraid = "gbraid",
    Gclid = "gclid",
    GroupId = "Group ID",
    GroupName = "Group Name",
    GroupPeriodEndDate = "Group Period End Date",
    GroupType = "Group Type",
    GroupUserId = "Group User ID",
    GroupUserRole = "Group User Role",
    HasEverJoinedFamily = "Has Ever Joined Family",
    IosAlternativeAppIcon = "iOS Alternative App Icon",
    Language = "language",
    LastAgreedPrivacyPolicy = "Last Agreed Privacy Policy",
    Library = "library",
    LocationAccess = "Location Access",
    MemberName = "Member Name",
    MobileInAppLightDarkMode = "Mobile In App Light Dark Mode",
    MoneyMovementRampDate = "Money Movement Ramp Date",
    Msclkid = "msclkid",
    OnboardingIncomeSituation = "Onboarding Income Situation",
    OnboardingUsersSituation = "Onboarding Users Situation",
    OpenLastUsedBudget = "Open Last Used Budget",
    OtherId = "Other ID",
    PermittedFeatures = "Permitted Features",
    Platform = "platform",
    PreRegistrationFeatureFlags = "Pre Registration Feature Flags",
    PricingScheme = "Pricing Scheme",
    PushNotificationAccess = "Push Notification Access",
    PushNotificationsAccess = "Push Notifications Access",
    ReferralFreeTimeApplied = "Referral Free Time Applied",
    ReferralProgramAvailable = "Referral Program Available",
    ReferredBy = "Referred By",
    Referrer = "referrer",
    Region = "region",
    SignUpDate = "Sign Up Date",
    SignupSource = "Signup Source",
    SubscriptionExpirationDate = "Subscription Expiration Date",
    SubscriptionSource = "Subscription Source",
    SubscriptionStatus = "Subscription Status",
    ToolkitUser = "Toolkit User",
    TrialDaysRemaining = "Trial Days Remaining",
    TrialExpirationDate = "Trial Expiration Date",
    TrialExtensionKey = "Trial Extension Key",
    Ttclid = "ttclid",
    Twclid = "twclid",
    Version = "version",
    Wbraid = "wbraid",
    WebBudgetBars = "Web Budget Bars",
    WebTheme = "Web Theme",
    Ynab4User = "YNAB 4 User",
}

export type AnalyticsEventProperties<EventKey extends keyof AnalyticsEventDefinitions> = Partial<
    AnalyticsEventDefinitions[EventKey]
>;

export type AnalyticsAccountsFilterValues =
    | AnalyticsPropertyValues.ShowAll
    | AnalyticsPropertyValues.ShowNone
    | AnalyticsPropertyValues.ShowSelection;

export type AnalyticsAccountTypeValues =
    | AnalyticsPropertyValues.Asset
    | AnalyticsPropertyValues.AutoLoan
    | AnalyticsPropertyValues.Cash
    | AnalyticsPropertyValues.Checking
    | AnalyticsPropertyValues.CreditCard
    | AnalyticsPropertyValues.Liability
    | AnalyticsPropertyValues.LineOfCredit
    | AnalyticsPropertyValues.MedicalDebt
    | AnalyticsPropertyValues.Mortgage
    | AnalyticsPropertyValues.OtherDebt
    | AnalyticsPropertyValues.PersonalLoan
    | AnalyticsPropertyValues.Savings
    | AnalyticsPropertyValues.StudentLoan
    | AnalyticsPropertyValues.Unknown;

export type AnalyticsActionValues = AnalyticsPropertyValues.DeselectAll | AnalyticsPropertyValues.SelectAll;

/**
 * Tracks the type of deleted and recorded activity in the Loan
 * Account.
 */
export type AnalyticsActivityTypeValues =
    | AnalyticsPropertyValues.Charge
    | AnalyticsPropertyValues.Escrow
    | AnalyticsPropertyValues.InterestCharge
    | AnalyticsPropertyValues.Payment
    | AnalyticsPropertyValues.Refund;

/**
 * Tracks the amount of the adjustment transaction (entered balance -
 * YNAB cleared balance)
 */
export type AnalyticsAdjustmentAmountValues = number;

/**
 * Tracks if the reconciliation resulted in an adjustment transaction.
 */
export type AnalyticsAdjustmentTransactionCreatedValues = boolean;

export type AnalyticsAssignmentMonthValues =
    | AnalyticsPropertyValues.CurrentMonth
    | AnalyticsPropertyValues.FutureMonth
    | AnalyticsPropertyValues.PriorMonth;

/**
 * Tracks where the auto-assign (fka quick budget) action was
 * initiated.
 */
export type AnalyticsAutoAssignLocationValues =
    | AnalyticsPropertyValues.AssignMode
    | AnalyticsPropertyValues.Banner
    | AnalyticsPropertyValues.Inspector
    | AnalyticsPropertyValues.Keypad;

export type AnalyticsCategoriesFilterValues =
    | AnalyticsPropertyValues.ShowAll
    | AnalyticsPropertyValues.ShowNone
    | AnalyticsPropertyValues.ShowSelection;

export type AnalyticsCategoryTemplateValues = string;

export type AnalyticsCategoryWizardResponseValues = string;

export type AnalyticsCloseMethodValues = AnalyticsPropertyValues.Continue | AnalyticsPropertyValues.Dismiss;

export type AnalyticsConnectionTypeValues =
    | AnalyticsPropertyValues.ExistingConnection
    | AnalyticsPropertyValues.Linked
    | AnalyticsPropertyValues.NewConnection
    | AnalyticsPropertyValues.Unlinked;

export type AnalyticsCorrectedMatchValues =
    | AnalyticsPropertyValues.False
    | AnalyticsPropertyValues.None
    | AnalyticsPropertyValues.True;

export type AnalyticsCountValues = any;

/**
 * Tracks whether the covered overspending covered the complete
 * overspending or only a part of it.
 */
export type AnalyticsCoverageValues = AnalyticsPropertyValues.Complete | AnalyticsPropertyValues.Partial;

export type AnalyticsCreditCardPaymentStatusValues =
    | AnalyticsPropertyValues.CreditOverspending
    | AnalyticsPropertyValues.FullyOrOverFunded
    | AnalyticsPropertyValues.FullyPaid
    | AnalyticsPropertyValues.UncategorizedTransactions
    | AnalyticsPropertyValues.Underfunded
    | AnalyticsPropertyValues.UnfundedPaymentMade;

/**
 * Records the currency an amount is in (ISO code)
 */
export type AnalyticsCurrencyValues = string;

/**
 * Tracks whether or not the flag being set has a custom name
 */
export type AnalyticsCustomFlagNameValues = boolean;

/**
 * Date Filter: user filters by date
 */
export type AnalyticsDateFilterValues =
    | AnalyticsPropertyValues.AllDates
    | AnalyticsPropertyValues.Custom
    | AnalyticsPropertyValues.LastYear
    | AnalyticsPropertyValues.Latest12Months
    | AnalyticsPropertyValues.Latest3Months
    | AnalyticsPropertyValues.Latest6Months
    | AnalyticsPropertyValues.ThisMonth
    | AnalyticsPropertyValues.ThisYear;

export type AnalyticsDebtTargetInputTypeValues =
    | AnalyticsPropertyValues.DueDate
    | AnalyticsPropertyValues.MonthlyPayment
    | AnalyticsPropertyValues.PayoffDate;

/**
 * Tracks which method was used to delete the transaction.
 */
export type AnalyticsDeletedTransactionMethodValues =
    | AnalyticsPropertyValues.BulkEdit
    | AnalyticsPropertyValues.EditMenu
    | AnalyticsPropertyValues.KeyboardShortcut
    | AnalyticsPropertyValues.RegisterActionBar
    | AnalyticsPropertyValues.Swipe
    | AnalyticsPropertyValues.TransactionDetailsView
    | AnalyticsPropertyValues.TransactionRowContextMenu;

export type AnalyticsDestinationUrlValues = string;

export type AnalyticsDetailValues =
    | AnalyticsPropertyValues.AvailableForPayment
    | AnalyticsPropertyValues.CurrentBalance;

/**
 * Whether or not the current balance we can pull from DI matches the
 * account's cleared balance, or if it's not valuable and why
 */
export type AnalyticsDirectImportCurrentBalanceMatchValues =
    | AnalyticsPropertyValues.BalanceMatch
    | AnalyticsPropertyValues.BalanceMismatch
    | AnalyticsPropertyValues.BalanceNotAvailable
    | AnalyticsPropertyValues.BalanceOutOfDate
    | AnalyticsPropertyValues.ConnectionInErrorState;

export type AnalyticsDirectImportProviderValues =
    | AnalyticsPropertyValues.Mx
    | AnalyticsPropertyValues.Plaid
    | AnalyticsPropertyValues.Truelayer;

export type AnalyticsDirectImportProviderErrorValues = any;

export type AnalyticsEscrowValues = any;

export type AnalyticsExitSurveyOptionValues = any;

export type AnalyticsFbiErrorValues = any;

export type AnalyticsFeelingRateValues =
    | AnalyticsPropertyValues.Confident
    | AnalyticsPropertyValues.Stable
    | AnalyticsPropertyValues.Stressed
    | AnalyticsPropertyValues.Unsure;

/**
 * Tracks which file type was used for the import.
 */
export type AnalyticsFileTypeValues =
    | AnalyticsPropertyValues.Csv
    | AnalyticsPropertyValues.Multiple
    | AnalyticsPropertyValues.Ofx
    | AnalyticsPropertyValues.Qfx
    | AnalyticsPropertyValues.Qif;

export type AnalyticsFinancialInstitutionValues = any;

export type AnalyticsFlagsWithCustomNamesValues = any;

/**
 * What kind of category money was moved from
 */
export type AnalyticsFromCategoryTypeValues =
    | AnalyticsPropertyValues.Cash
    | AnalyticsPropertyValues.Credit
    | AnalyticsPropertyValues.ReadyToAssign;

export type AnalyticsFromTbbOrToTbbValues = AnalyticsPropertyValues.FromTbb | AnalyticsPropertyValues.ToTbb;

/**
 * There’s a translation option for the toolkit and to get these goal
 * values into Amplitude we kept it simple by passing in the string
 * from the goal type. What we didn’t realize is that if users have
 * changed the language using the toolkit we pick that up as a
 * byproduct. There isn’t enough of them to skew the results—it’s just
 * a mild annoyance to scroll through them all to find what you’re
 * looking for.
 */
export type AnalyticsGoalTypeValues =
    | AnalyticsPropertyValues.HaveABalance
    | AnalyticsPropertyValues.HaveABalanceByDate
    | AnalyticsPropertyValues.MonthlyDebtPaymentPaired
    | AnalyticsPropertyValues.MonthlyDebtPaymentUnpaired
    | AnalyticsPropertyValues.PayOffBalanceByDate
    | AnalyticsPropertyValues.PaySpecificAmountEachMonth
    | AnalyticsPropertyValues.RefillUpToByDate
    | AnalyticsPropertyValues.RefillUpToCustomRepeat
    | AnalyticsPropertyValues.RefillUpToMonthly
    | AnalyticsPropertyValues.RefillUpToWeekly
    | AnalyticsPropertyValues.RefillUpToYearly
    | AnalyticsPropertyValues.SetAsideAnotherByDate
    | AnalyticsPropertyValues.SetAsideAnotherCustomRepeat
    | AnalyticsPropertyValues.SetAsideAnotherMonthly
    | AnalyticsPropertyValues.SetAsideAnotherWeekly
    | AnalyticsPropertyValues.SetAsideAnotherYearly;

export type AnalyticsImportedAccountTypeValues = any;

export type AnalyticsImportedSelectCategoriesValues = AnalyticsPropertyValues.No | AnalyticsPropertyValues.Yes;

/**
 * To identify the different steps in the Mint Importer flow.
 */
export type AnalyticsImporterStepValues =
    | AnalyticsPropertyValues.ClosedMintMigration
    | AnalyticsPropertyValues.CompletedMintVideoStep
    | AnalyticsPropertyValues.SelectedOnboardingPath
    | AnalyticsPropertyValues.UploadedMintFile;

/**
 * Tracks whether the Update Balance flow in a debt account changed
 * the interest rate, matched the interest amount or neither.   If the
 * amount was matched, no balance adjustment is made once the user
 * finishes the flow.
 */
export type AnalyticsInterestValues =
    | AnalyticsPropertyValues.Changed
    | AnalyticsPropertyValues.Matched
    | AnalyticsPropertyValues.Neither;

/**
 * This tracks the location of events. It's useful for events that can
 * be triggered in multiple places.
 */
export type AnalyticsLocationValues =
    | AnalyticsPropertyValues.Account
    | AnalyticsPropertyValues.AccountEditor
    | AnalyticsPropertyValues.AccountList
    | AnalyticsPropertyValues.AccountWidget
    | AnalyticsPropertyValues.ActionBar
    | AnalyticsPropertyValues.AfterWizard
    | AnalyticsPropertyValues.AllAccounts
    | AnalyticsPropertyValues.AssignMode
    | AnalyticsPropertyValues.Banner
    | AnalyticsPropertyValues.Budget
    | AnalyticsPropertyValues.BudgetHeader
    | AnalyticsPropertyValues.BudgetMenu
    | AnalyticsPropertyValues.BudgetRow
    | AnalyticsPropertyValues.Categories
    | AnalyticsPropertyValues.CategoryDetails
    | AnalyticsPropertyValues.CategoryGroup
    | AnalyticsPropertyValues.CategorySheet
    | AnalyticsPropertyValues.CostToBeMe
    | AnalyticsPropertyValues.Dropdown
    | AnalyticsPropertyValues.EditPlan
    | AnalyticsPropertyValues.EditTargetScreen
    | AnalyticsPropertyValues.FtueClassicEditMode
    | AnalyticsPropertyValues.Inspector
    | AnalyticsPropertyValues.InstitutionList
    | AnalyticsPropertyValues.KeypadBanner
    | AnalyticsPropertyValues.LandingScreen
    | AnalyticsPropertyValues.LongPressCategoryOnBudgetScreen
    | AnalyticsPropertyValues.LongPressCategoryOnBudgetScreenIos
    | AnalyticsPropertyValues.ManagePayees
    | AnalyticsPropertyValues.MoreMenu
    | AnalyticsPropertyValues.MoveMoneyPopover
    | AnalyticsPropertyValues.MyPlan
    | AnalyticsPropertyValues.PayeePopover
    | AnalyticsPropertyValues.PlanSelectionScreen
    | AnalyticsPropertyValues.PopularInstitutions
    | AnalyticsPropertyValues.RecentMoves
    | AnalyticsPropertyValues.RightClickMenu
    | AnalyticsPropertyValues.Settings
    | AnalyticsPropertyValues.SettingsDropdown
    | AnalyticsPropertyValues.SettingsMenu
    | AnalyticsPropertyValues.SettingsPage
    | AnalyticsPropertyValues.Sidebar
    | AnalyticsPropertyValues.SpendingReport
    | AnalyticsPropertyValues.Spotlight
    | AnalyticsPropertyValues.StartedTrial
    | AnalyticsPropertyValues.SwipeLeftIos
    | AnalyticsPropertyValues.TransactionDetailsView;

/**
 * Tracks how the user logged into their account.
 */
export type AnalyticsLogInMethodValues =
    | AnalyticsPropertyValues.Apple
    | AnalyticsPropertyValues.Email
    | AnalyticsPropertyValues.Google;

/**
 * Tracks which method was used to match the transaction.
 */
export type AnalyticsMatchedTransactionMethodValues =
    | AnalyticsPropertyValues.BulkEdit
    | AnalyticsPropertyValues.EditMenu
    | AnalyticsPropertyValues.KeyboardShortcut
    | AnalyticsPropertyValues.RegisterActionBar
    | AnalyticsPropertyValues.TransactionRowContextMenu;

export type AnalyticsMigrationPathValues = AnalyticsPropertyValues.Menu | AnalyticsPropertyValues.SignUp;

/**
 * For now, only Mint is possible as the Migration Source.
 */
export type AnalyticsMigrationSourceValues = AnalyticsPropertyValues.Mint;

/**
 * Month \[in relation to the present month\] the history icon was
 * clicked
 */
export type AnalyticsMonthDeltaValues = number;

export type AnalyticsMovesTypeFilterValues =
    | AnalyticsPropertyValues.All
    | AnalyticsPropertyValues.Assigned
    | AnalyticsPropertyValues.Moved;

export type AnalyticsMultipleWarningsValues = boolean;

export type AnalyticsNavigationSourceValues =
    | AnalyticsPropertyValues.AssignedInFutureMonths
    | AnalyticsPropertyValues.EvergreenNavigationArrows
    | AnalyticsPropertyValues.MonthPicker;

export type AnalyticsNewTargetValues = string;

/**
 * Is the notes field empty at the start of this action, or is there
 * something there already?
 */
export type AnalyticsNoteInitialStateValues = AnalyticsPropertyValues.Empty | AnalyticsPropertyValues.Filled;

export type AnalyticsOn_offValues = AnalyticsPropertyValues.ToggledOff | AnalyticsPropertyValues.ToggledOn;

export type AnalyticsOnboardingPathValues = string;

export type AnalyticsOpenConversationCountValues = number;

export type AnalyticsOpenConversationsIdsValues = string;

export type AnalyticsOutroStateValues =
    | AnalyticsPropertyValues.Exact
    | AnalyticsPropertyValues.Negative
    | AnalyticsPropertyValues.Positive;

export type AnalyticsPageNameValues = string;

export type AnalyticsPairedCategoryValues = any;

/**
 * Tracks what kind of value a user copies from the debt account
 * paydown analysis over to their budget.
 */
export type AnalyticsPaydownTypeValues =
    | AnalyticsPropertyValues.OneTimePayment
    | AnalyticsPropertyValues.Skip
    | AnalyticsPropertyValues.Target;

export type AnalyticsPendingTransactionsFilterValues =
    | AnalyticsPropertyValues.HidePendingTransactions
    | AnalyticsPropertyValues.ShowPendingTransactions;

export type AnalyticsPercentageOfCategoriesThatHaveTargetsValues = number;

export type AnalyticsPercentageOfCategoriesWhichWereAssignedToValues = number;

export type AnalyticsPercentageOfRemainingInRtaValues = number;

export type AnalyticsPlaidAccountsLinkedCountValues = number;

export type AnalyticsPlaidChosePreselectedInstitutionValues = string;

export type AnalyticsPlaidExitReasonSummaryValues = string;

export type AnalyticsPlaidPhoneFieldValues =
    | AnalyticsPropertyValues.Attempted
    | AnalyticsPropertyValues.NotShown
    | AnalyticsPropertyValues.Shown
    | AnalyticsPropertyValues.Skipped
    | AnalyticsPropertyValues.SkippedAfterAttempt
    | AnalyticsPropertyValues.Verified;

export type AnalyticsPlaidSavedAccountsScreenValues =
    | AnalyticsPropertyValues.NotShown
    | AnalyticsPropertyValues.Selected
    | AnalyticsPropertyValues.Shown
    | AnalyticsPropertyValues.ShownButUnwanted;

export type AnalyticsPlaidSavedInstitutionsScreenValues =
    | AnalyticsPropertyValues.NotShown
    | AnalyticsPropertyValues.Selected
    | AnalyticsPropertyValues.Shown
    | AnalyticsPropertyValues.ShownButUnwanted;

export type AnalyticsPlaidWidgetOpenDurationValues = number;

/**
 * Describes the state that the user's register was in when the CTA
 * was clicked.
 */
export type AnalyticsProcessingStateValues =
    | AnalyticsPropertyValues.Complete
    | AnalyticsPropertyValues.MoreTransactions
    | AnalyticsPropertyValues.Overspent
    | AnalyticsPropertyValues.OverspentMoreTransactions;

export type AnalyticsQuickBudgetCountValues = any;

export type AnalyticsQuickBudgetMethodValues = any;

export type AnalyticsQuickBudgetTypeValues = any;

export type AnalyticsReconciledTransactionFilterValues =
    | AnalyticsPropertyValues.HideReconciledTransactions
    | AnalyticsPropertyValues.ShowReconciledTransactions;

export type AnalyticsReconciliationStepValues = any;

export type AnalyticsReferralMethodValues = any;

/**
 * which reflection was filtered
 */
export type AnalyticsReflectionPathValues =
    | AnalyticsPropertyValues.IncomeVExpense
    | AnalyticsPropertyValues.NetWorth
    | AnalyticsPropertyValues.SpendingBreakdown
    | AnalyticsPropertyValues.SpendingTrends;

/**
 * Tracks which method was used to reject the transaction.
 */
export type AnalyticsRejectedTransactionMethodValues =
    | AnalyticsPropertyValues.ApprovalPopover
    | AnalyticsPropertyValues.BulkEdit
    | AnalyticsPropertyValues.EditMenu
    | AnalyticsPropertyValues.KeyboardShortcut
    | AnalyticsPropertyValues.MatchingPopover
    | AnalyticsPropertyValues.RegisterActionBar
    | AnalyticsPropertyValues.TransactionDetailsView
    | AnalyticsPropertyValues.TransactionRowContextMenu;

export type AnalyticsRenamingPathValues =
    | AnalyticsPropertyValues.EditImportedTransaction
    | AnalyticsPropertyValues.ManagePayees;

export type AnalyticsReportTypeValues =
    | AnalyticsPropertyValues.IncomeVExpense
    | AnalyticsPropertyValues.NetWorth
    | AnalyticsPropertyValues.Spending;

/**
 * Tracks which Help resource was viewed, when a user opens a resource
 * from the Mobile Help.
 */
export type AnalyticsResourceValues = any;

/**
 * The position of the response.
 */
export type AnalyticsResponsePositionValues = number;

export type AnalyticsRtaStateValues =
    | AnalyticsPropertyValues.Negative
    | AnalyticsPropertyValues.Positive
    | AnalyticsPropertyValues.Zero;

export type AnalyticsScheduledTransactionsFilterValues =
    | AnalyticsPropertyValues.HideScheduledTransactions
    | AnalyticsPropertyValues.ShowScheduledTransactions;

export type AnalyticsSearchedAccountValues = boolean;

export type AnalyticsSearchedApprovedValues = boolean;

export type AnalyticsSearchedCategoryValues = boolean;

export type AnalyticsSearchedClearedValues = boolean;

export type AnalyticsSearchedDateValues = boolean;

export type AnalyticsSearchedFlagValues = boolean;

export type AnalyticsSearchedInflowValues = boolean;

export type AnalyticsSearchedMemoValues = boolean;

export type AnalyticsSearchedNeedsCategoryValues = boolean;

export type AnalyticsSearchedOutflowValues = boolean;

export type AnalyticsSearchedPayeeValues = boolean;

export type AnalyticsSearchedReconciledValues = boolean;

export type AnalyticsSearchedUnapprovedValues = boolean;

export type AnalyticsSearchedUnclearedValues = boolean;

export type AnalyticsSearchedUnreconciledValues = boolean;

/**
 * This property reflects the final state of the section header when
 * clicked.
 */
export type AnalyticsSectionHeaderEndStateValues = AnalyticsPropertyValues.Collapsed | AnalyticsPropertyValues.Expanded;

/**
 * Describes which section header was expanded/collapsed.
 */
export type AnalyticsSectionHeaderTypeValues = AnalyticsPropertyValues.Pending | AnalyticsPropertyValues.Scheduled;

/**
 * Tracks which method was used to set the account for the
 * transaction.
 */
export type AnalyticsSetAccountMethodValues =
    | AnalyticsPropertyValues.BulkEdit
    | AnalyticsPropertyValues.EditMenu
    | AnalyticsPropertyValues.RegisterActionBar
    | AnalyticsPropertyValues.TransactionDetailsView
    | AnalyticsPropertyValues.TransactionRow
    | AnalyticsPropertyValues.TransactionRowContextMenu;

/**
 * Tracks which method was used to set the flag for the transaction.
 */
export type AnalyticsSetFlagMethodValues =
    | AnalyticsPropertyValues.BulkEdit
    | AnalyticsPropertyValues.KeyboardShortcut
    | AnalyticsPropertyValues.RegisterActionBar
    | AnalyticsPropertyValues.TransactionDetailsView
    | AnalyticsPropertyValues.TransactionRowContextMenu;

/**
 * Tracks how the user signed up for their account (e.g. Apple, Email,
 * Google).
 */
export type AnalyticsSignUpMethodValues =
    | AnalyticsPropertyValues.Apple
    | AnalyticsPropertyValues.Email
    | AnalyticsPropertyValues.Google;

/**
 * The platform used for sign up (e.g. iOS, Evergreen, Android).
 */
export type AnalyticsSignUpSourceValues =
    | AnalyticsPropertyValues.Android
    | AnalyticsPropertyValues.Ios
    | AnalyticsPropertyValues.Web;

export type AnalyticsSpendingRecipientTypeValues =
    | AnalyticsPropertyValues.Kids
    | AnalyticsPropertyValues.MyPartner
    | AnalyticsPropertyValues.Myself
    | AnalyticsPropertyValues.OtherAdults
    | AnalyticsPropertyValues.Pets
    | AnalyticsPropertyValues.Teens13;

export type AnalyticsStartedReconciliationMethodValues = AnalyticsPropertyValues.KeyboardShortcut;

export type AnalyticsStartSourceValues = AnalyticsPropertyValues.AutoPresented | AnalyticsPropertyValues.ClickedToView;

/**
 * Tracks the state of interest or escrow charge before it is saved to
 * its new state in loan accounts.
 */
export type AnalyticsStatusValues =
    | AnalyticsPropertyValues.Edited
    | AnalyticsPropertyValues.Estimated
    | AnalyticsPropertyValues.Reconciled;

export type AnalyticsStopImportingTransactionsSelectionValues = boolean;

/**
 * This records which plan was selected when subscribing, renewing, or
 * modifying the subscription.
 */
export type AnalyticsSubscriptionPlanValues =
    | AnalyticsPropertyValues.Annual
    | AnalyticsPropertyValues.FinancialWellnessPlan
    | AnalyticsPropertyValues.Monthly;

/**
 * Tracks where the subscription is stored.
 */
export type AnalyticsSubscriptionProviderValues =
    | AnalyticsPropertyValues.GoogleInAppPurchase
    | AnalyticsPropertyValues.InAppPurchase
    | AnalyticsPropertyValues.Recurly;

export type AnalyticsSufficientIncomeValues =
    | AnalyticsPropertyValues.False
    | AnalyticsPropertyValues.True
    | AnalyticsPropertyValues.Unknown;

export type AnalyticsTargetSnoozeLocationValues = string;

/**
 * What kind of category money was moved to
 */
export type AnalyticsToCategoryTypeValues =
    | AnalyticsPropertyValues.Cash
    | AnalyticsPropertyValues.Credit
    | AnalyticsPropertyValues.ReadyToAssign;

export type AnalyticsTransactionContextMenuSelectionValues =
    | AnalyticsPropertyValues.ApproveOption
    | AnalyticsPropertyValues.CategorizeOption
    | AnalyticsPropertyValues.ClearUnclearOption
    | AnalyticsPropertyValues.DeleteOption
    | AnalyticsPropertyValues.DuplicateOption
    | AnalyticsPropertyValues.EnterNowOption
    | AnalyticsPropertyValues.FlagOption
    | AnalyticsPropertyValues.MatchUnmatchOption
    | AnalyticsPropertyValues.MoveOption
    | AnalyticsPropertyValues.RejectOption;

/**
 * A count of transactions
 */
export type AnalyticsTransactionCountValues = number;

/**
 * Records the state of a transaction when it was modified. For
 * example, when a pending transaction is approved this event property
 * should be recorded as pending not posted.
 */
export type AnalyticsTransactionStateValues =
    | AnalyticsPropertyValues.Pending
    | AnalyticsPropertyValues.PostedPending
    | AnalyticsPropertyValues.Scheduled
    | AnalyticsPropertyValues.UserEntered;

/**
 * When an event refers to multiple transactions, details the types of
 * those transactions included.
 */
export type AnalyticsTransactionsTypeValues =
    | AnalyticsPropertyValues.Pending
    | AnalyticsPropertyValues.Scheduled
    | AnalyticsPropertyValues.ScheduledPending;

/**
 * Tracks which method was used to unmatch the transaction.
 */
export type AnalyticsUnmatchedTransactionMethodValues =
    | AnalyticsPropertyValues.BulkEdit
    | AnalyticsPropertyValues.EditMenu
    | AnalyticsPropertyValues.KeyboardShortcut
    | AnalyticsPropertyValues.MatchDetails
    | AnalyticsPropertyValues.MatchingPopover
    | AnalyticsPropertyValues.RegisterActionBar
    | AnalyticsPropertyValues.TransactionDetailsView
    | AnalyticsPropertyValues.TransactionRowContextMenu;

export type AnalyticsUsedEnterNowMethodValues = AnalyticsPropertyValues.KeyboardShortcut;

/**
 * If the user is during onboarding or past onboarding.
 */
export type AnalyticsUserJourneyStateValues = AnalyticsPropertyValues.FullBudget | AnalyticsPropertyValues.Onboarding;

/**
 * This will be tied to the user, but it will initially need to be
 * tied to each event to get multi-touch attribution data.
 */
export type AnalyticsUtmCampaignValues = string;

/**
 * This will be tied to the user, but it will initially need to be
 * tied to each event to get multi-touch attribution data.
 */
export type AnalyticsUtmContentValues = string;

/**
 * This will be tied to the user, but it will initially need to be
 * tied to each event to get multi-touch attribution data.
 */
export type AnalyticsUtmMediumValues = string;

/**
 * This will be tied to the user, but it will initially need to be
 * tied to each event to get multi-touch attribution data.
 */
export type AnalyticsUtmSourceValues = string;

export type AnalyticsViewedActivityMethodValues =
    | AnalyticsPropertyValues.ActivityPopover
    | AnalyticsPropertyValues.BudgetRowContextMenu
    | AnalyticsPropertyValues.CategoryDetails;

export type AnalyticsViewTypeValues = string;

export type AnalyticsWidgetReasonValues =
    | AnalyticsPropertyValues.ForethoughtDisabled
    | AnalyticsPropertyValues.ForethoughtEnabled
    | AnalyticsPropertyValues.OpenKustomerConversations;

export type AnalyticsWidgetSelectedValues = AnalyticsPropertyValues.Forethought | AnalyticsPropertyValues.Kustomer;

export type AnalyticsWizardStepValues =
    | AnalyticsPropertyValues.CompletedWizard
    | AnalyticsPropertyValues.DebtInterstitial
    | AnalyticsPropertyValues.DebtQuestion
    | AnalyticsPropertyValues.FeelingsInterstitial
    | AnalyticsPropertyValues.FeelingsQuestion
    | AnalyticsPropertyValues.FinalQuestion
    | AnalyticsPropertyValues.HomeQuestion
    | AnalyticsPropertyValues.InspirationalInterstitial
    | AnalyticsPropertyValues.Loading
    | AnalyticsPropertyValues.MortgageQuestion
    | AnalyticsPropertyValues.NameQuestion
    | AnalyticsPropertyValues.PartnerInterstitial
    | AnalyticsPropertyValues.PartnerQuestion
    | AnalyticsPropertyValues.PrioritiesInterstitial
    | AnalyticsPropertyValues.RegularSpendQuestion
    | AnalyticsPropertyValues.SavingsQuestion
    | AnalyticsPropertyValues.SelfReportedSourceQuestion
    | AnalyticsPropertyValues.StartedWizard
    | AnalyticsPropertyValues.SubscriptionsQuestion
    | AnalyticsPropertyValues.TransportationQuestion
    | AnalyticsPropertyValues.TrueExpensesInterstitial
    | AnalyticsPropertyValues.TrueExpensesQuestion;

export type AnalyticsZeroBalanceValues = string;

export type AnalyticsEventDefinitions = {
    /**
     * Client side. A CTA on the loan planner/overview prompting the user
     * to complete missing loan details.
     * https://app.amplitude.com/data/ynab/Production/events/main/latest/Add%20Loan%20Account%20Details
     */
    [AnalyticsEvents.Accounts_AddLoanAccountDetails]: {};
    /**
     * Evergreen: Triggered when clicking Add Account in the sidebar.
     * Mobile: Triggered when clicking Add Account in the Accounts view.
     * Both of these actions bring up the Account Widget.
     * https://app.amplitude.com/data/ynab/Production/events/main/latest/Clicked%20Add%20Account
     */
    [AnalyticsEvents.Accounts_ClickedAddAccount]: {};
    /**
     * User clicks "add new connection" in the Account Widget connections
     * screen.
     * https://app.amplitude.com/data/ynab/Production/events/main/latest/Clicked%20Add%20Connection
     */
    [AnalyticsEvents.Accounts_ClickedAddConnection]: {};
    /**
     * User chooses an existing connection in the Account Widget
     * connections screen.
     * https://app.amplitude.com/data/ynab/Production/events/main/latest/Clicked%20Existing%20Connection
     */
    [AnalyticsEvents.Accounts_ClickedExistingConnection]: {};
    /**
     * Triggered when customer clicks on scheduled/pending section header
     * in accounts.
     * https://app.amplitude.com/data/ynab/Production/events/main/latest/Clicked%20Section%20Header
     */
    [AnalyticsEvents.Accounts_ClickedSectionHeader]: {
        [AnalyticsProperties.SectionHeaderEndState]: AnalyticsSectionHeaderEndStateValues;
        [AnalyticsProperties.SectionHeaderType]: AnalyticsSectionHeaderTypeValues;
        [AnalyticsProperties.TransactionCount]: AnalyticsTransactionCountValues;
    };
    /**
     * Triggered when a user clicks on the View All Transactions CTA on
     * the register empty state following transaction processing.
     * https://app.amplitude.com/data/ynab/Production/events/main/latest/Clicked%20View%20All%20Transactions
     */
    [AnalyticsEvents.Accounts_ClickedViewAllTransactions]: {
        [AnalyticsProperties.ProcessingState]: AnalyticsProcessingStateValues;
    };
    /**
     * Triggered when a user clicks on the View My Budget CTA on the
     * register empty state following transaction processing
     * https://app.amplitude.com/data/ynab/Production/events/main/latest/Clicked%20View%20My%20Budget
     */
    [AnalyticsEvents.Accounts_ClickedViewMyBudget]: {};
    /**
     * Triggered when a user clicks on the View Overspent Categories CTA
     * on the register empty state.
     * https://app.amplitude.com/data/ynab/Production/events/main/latest/Clicked%20View%20Overspent%20Categories
     */
    [AnalyticsEvents.Accounts_ClickedViewOverspentCategories]: {
        [AnalyticsProperties.ProcessingState]: AnalyticsProcessingStateValues;
    };
    /**
     * Triggered when a user clicks on the View Remaining Transactions CTA
     * on the register empty state following transaction processing in an
     * individual account.
     * https://app.amplitude.com/data/ynab/Production/events/main/latest/Clicked%20View%20Remaining%20Transactions
     */
    [AnalyticsEvents.Accounts_ClickedViewRemainingTransactions]: {
        [AnalyticsProperties.ProcessingState]: AnalyticsProcessingStateValues;
    };
    /**
     * Triggered when clicking Close Account in the account details
     * popover. Accounts can only be closed, not deleted, if there are
     * transactions recorded.
     * https://app.amplitude.com/data/ynab/Production/events/main/latest/Closed%20Account
     */
    [AnalyticsEvents.Accounts_ClosedAccount]: {
        [AnalyticsProperties.AccountType]: AnalyticsAccountTypeValues;
    };
    /**
     * Triggered when a customer combines multiple payees in Manage
     * Payees.
     * https://app.amplitude.com/data/ynab/Production/events/main/latest/Combined%20Payees
     */
    [AnalyticsEvents.Accounts_CombinedPayees]: {
        [AnalyticsProperties.Count]: AnalyticsCountValues;
    };
    /**
     * Evergreen: Triggered when either copy link is used in the paydown
     * analysis section of the debt account page
     * https://app.amplitude.com/data/ynab/Production/events/main/latest/Copied%20Paydown%20Analysis%20Amount%20to%20Budget
     */
    [AnalyticsEvents.Accounts_CopiedPaydownAnalysisAmountToBudget]: {
        [AnalyticsProperties.PaydownType]: AnalyticsPaydownTypeValues;
    };
    /**
     * Triggered when the account is saved using the Account Widget.
     * https://app.amplitude.com/data/ynab/Production/events/main/latest/Created%20Account
     */
    [AnalyticsEvents.Accounts_CreatedAccount]: {
        [AnalyticsProperties.AccountType]: AnalyticsAccountTypeValues;
        [AnalyticsProperties.ConnectionType]: AnalyticsConnectionTypeValues;
        [AnalyticsProperties.FinancialInstitution]: AnalyticsFinancialInstitutionValues;
        [AnalyticsProperties.ImportedAccountType]: AnalyticsImportedAccountTypeValues;
        [AnalyticsProperties.ZeroBalance]: AnalyticsZeroBalanceValues;
    };
    /**
     * Client side. Triggered when a user overrides an imported payee with
     * a new or existing payee; also triggered when a user manually
     * creates a renaming rule from Manage Payees.
     * https://app.amplitude.com/data/ynab/Production/events/main/latest/Created%20Payee%20Renaming%20Rule
     */
    [AnalyticsEvents.Accounts_CreatedPayeeRenamingRule]: {
        [AnalyticsProperties.RenamingPath]: AnalyticsRenamingPathValues;
    };
    /**
     * Triggered when clicking Delete Account in the account details
     * popover. Accounts can be deleted if there are no transactions
     * recorded.
     * https://app.amplitude.com/data/ynab/Production/events/main/latest/Deleted%20Bank%20Account
     */
    [AnalyticsEvents.Accounts_DeletedBankAccount]: {
        [AnalyticsProperties.AccountType]: AnalyticsAccountTypeValues;
    };
    /**
     * Triggered when a user clicks delete in the modal.
     * https://app.amplitude.com/data/ynab/Production/events/main/latest/Deleted%20Loan%20Activity
     */
    [AnalyticsEvents.Accounts_DeletedLoanActivity]: {
        [AnalyticsProperties.ActivityType]: AnalyticsActivityTypeValues;
    };
    /**
     * Triggered when a user clicks save after they edit a charge.
     * https://app.amplitude.com/data/ynab/Production/events/main/latest/Edited%20Loan%20Activity
     */
    [AnalyticsEvents.Accounts_EditedLoanActivity]: {
        [AnalyticsProperties.ActivityType]: AnalyticsActivityTypeValues;
        [AnalyticsProperties.Status]: AnalyticsStatusValues;
    };
    /**
     * User encountered an error when creating a new direct import
     * connection or reauthenticating an existing connection.
     * https://app.amplitude.com/data/ynab/Production/events/main/latest/Encountered%20Direct%20Import%20Error
     */
    [AnalyticsEvents.Accounts_EncounteredDirectImportError]: {
        [AnalyticsProperties.DirectImportProvider]: AnalyticsDirectImportProviderValues;
        [AnalyticsProperties.DirectImportProviderError]: AnalyticsDirectImportProviderErrorValues;
    };
    /**
     * Triggered when a user filters within All Accounts or an account.
     * https://app.amplitude.com/data/ynab/Production/events/main/latest/Filtered%20Account
     */
    [AnalyticsEvents.Accounts_FilteredAccount]: {
        [AnalyticsProperties.DateFilter]: AnalyticsDateFilterValues;
        [AnalyticsProperties.PendingTransactionsFilter]: AnalyticsPendingTransactionsFilterValues;
        [AnalyticsProperties.ReconciledTransactionFilter]: AnalyticsReconciledTransactionFilterValues;
        [AnalyticsProperties.ScheduledTransactionsFilter]: AnalyticsScheduledTransactionsFilterValues;
    };
    /**
     * Triggered when a user clicks Finish Reconciliation in an account.
     * https://app.amplitude.com/data/ynab/Production/events/main/latest/Finished%20Reconciliation
     */
    [AnalyticsEvents.Accounts_FinishedReconciliation]: {
        [AnalyticsProperties.AdjustmentAmount]: AnalyticsAdjustmentAmountValues;
        [AnalyticsProperties.AdjustmentTransactionCreated]: AnalyticsAdjustmentTransactionCreatedValues;
        [AnalyticsProperties.Currency]: AnalyticsCurrencyValues;
    };
    /**
     * Triggered when a user hides Running Balance through the View menu
     * https://app.amplitude.com/data/ynab/Production/events/main/latest/Hid%20Running%20Balance
     */
    [AnalyticsEvents.Accounts_HidRunningBalance]: {
        [AnalyticsProperties.ConnectionType]: AnalyticsConnectionTypeValues;
    };
    /**
     * Triggered when an existing category is selected during the account
     * creation flow for a debt account.
     * https://app.amplitude.com/data/ynab/Production/events/main/latest/Paired%20Account%20with%20Existing%20Category
     */
    [AnalyticsEvents.Accounts_PairedAccountWithExistingCategory]: {
        [AnalyticsProperties.Location]: AnalyticsLocationValues;
    };
    /**
     * Triggered when "Create New Category" is selected during the account
     * creation flow for a debt account.
     * https://app.amplitude.com/data/ynab/Production/events/main/latest/Paired%20Account%20with%20New%20Category
     */
    [AnalyticsEvents.Accounts_PairedAccountWithNewCategory]: {
        [AnalyticsProperties.Location]: AnalyticsLocationValues;
    };
    /**
     * Triggered when user clicks save after they record the activity.
     * https://app.amplitude.com/data/ynab/Production/events/main/latest/Recorded%20Loan%20Activity
     */
    [AnalyticsEvents.Accounts_RecordedLoanActivity]: {
        [AnalyticsProperties.ActivityType]: AnalyticsActivityTypeValues;
    };
    /**
     * Triggered when a user searches within All Accounts or an account.
     * For iOS, this event is sent when the search text changes, but at
     * most once per five seconds.  For Android, [insert search event
     * notes here]
     * https://app.amplitude.com/data/ynab/Production/events/main/latest/Searched%20Account
     */
    [AnalyticsEvents.Accounts_SearchedAccount]: {
        [AnalyticsProperties.SearchedAccount]: AnalyticsSearchedAccountValues;
        [AnalyticsProperties.SearchedApproved]: AnalyticsSearchedApprovedValues;
        [AnalyticsProperties.SearchedCategory]: AnalyticsSearchedCategoryValues;
        [AnalyticsProperties.SearchedCleared]: AnalyticsSearchedClearedValues;
        [AnalyticsProperties.SearchedDate]: AnalyticsSearchedDateValues;
        [AnalyticsProperties.SearchedFlag]: AnalyticsSearchedFlagValues;
        [AnalyticsProperties.SearchedInflow]: AnalyticsSearchedInflowValues;
        [AnalyticsProperties.SearchedMemo]: AnalyticsSearchedMemoValues;
        [AnalyticsProperties.SearchedNeedsCategory]: AnalyticsSearchedNeedsCategoryValues;
        [AnalyticsProperties.SearchedOutflow]: AnalyticsSearchedOutflowValues;
        [AnalyticsProperties.SearchedPayee]: AnalyticsSearchedPayeeValues;
        [AnalyticsProperties.SearchedReconciled]: AnalyticsSearchedReconciledValues;
        [AnalyticsProperties.SearchedUnapproved]: AnalyticsSearchedUnapprovedValues;
        [AnalyticsProperties.SearchedUncleared]: AnalyticsSearchedUnclearedValues;
        [AnalyticsProperties.SearchedUnreconciled]: AnalyticsSearchedUnreconciledValues;
    };
    /**
     * Triggered when a user selects their connection type intention in
     * the account widget, regardless of what the ultimate connection type
     * is. For example, a user may select linked with this event but end
     * up with a Created Account: Unlinked event if a Direct Import error
     * prevented a connection.
     * https://app.amplitude.com/data/ynab/Production/events/main/latest/Selected%20Account%20Connection%20Type
     */
    [AnalyticsEvents.Accounts_SelectedAccountConnectionType]: {
        [AnalyticsProperties.ConnectionType]: AnalyticsConnectionTypeValues;
    };
    /**
     * Triggered when a customer selects an institution either from search
     * results or our suggestions. Only available for MX and TrueLayer.
     * https://app.amplitude.com/data/ynab/Production/events/main/latest/Selected%20Institution
     */
    [AnalyticsEvents.Accounts_SelectedInstitution]: {
        [AnalyticsProperties.FinancialInstitution]: AnalyticsFinancialInstitutionValues;
        [AnalyticsProperties.Location]: AnalyticsLocationValues;
    };
    /**
     * Triggered when a user shows Running Balance through the View menu
     * https://app.amplitude.com/data/ynab/Production/events/main/latest/Showed%20Running%20Balance
     */
    [AnalyticsEvents.Accounts_ShowedRunningBalance]: {
        [AnalyticsProperties.ConnectionType]: AnalyticsConnectionTypeValues;
    };
    /**
     * Triggered when a user clicks the skip button in the account
     * creation flow of a loan account.
     * https://app.amplitude.com/data/ynab/Production/events/main/latest/Skipped%20Category%20Pairing
     */
    [AnalyticsEvents.Accounts_SkippedCategoryPairing]: {};
    /**
     * Triggered when a user clicked "Get Started" in the debt account
     * migration pop up.
     * https://app.amplitude.com/data/ynab/Production/events/main/latest/Started%20Debt%20Account%20Migration
     */
    [AnalyticsEvents.Accounts_StartedDebtAccountMigration]: {};
    /**
     * Triggered when a user clicks the primary CTA in the starting
     * balance error message shown in the paydown analysis section of a
     * debt account.
     * https://app.amplitude.com/data/ynab/Production/events/main/latest/Started%20Debt%20Starting%20Balance%20Error%20Fix
     */
    [AnalyticsEvents.Accounts_StartedDebtStartingBalanceErrorFix]: {};
    /**
     * Triggered when a user clicks Reconcile in an account.
     * https://app.amplitude.com/data/ynab/Production/events/main/latest/Started%20Reconciliation
     */
    [AnalyticsEvents.Accounts_StartedReconciliation]: {
        [AnalyticsProperties.StartedReconciliationMethod]: AnalyticsStartedReconciliationMethodValues;
    };
    /**
     * Triggered when a user clicks the unpair button in the confirmation
     * modal of the loan account settings
     * https://app.amplitude.com/data/ynab/Production/events/main/latest/Unpaired%20Account%20from%20Category
     */
    [AnalyticsEvents.Accounts_UnpairedAccountFromCategory]: {};
    /**
     * Evergreen: Triggered when the update balance flow on a debt account
     * is finished.
     * https://app.amplitude.com/data/ynab/Production/events/main/latest/Update%20Balance
     */
    [AnalyticsEvents.Accounts_UpdateBalance]: {
        [AnalyticsProperties.AdjustmentAmount]: AnalyticsAdjustmentAmountValues;
        [AnalyticsProperties.CorrectedMatch]: AnalyticsCorrectedMatchValues;
        [AnalyticsProperties.Currency]: AnalyticsCurrencyValues;
        [AnalyticsProperties.Escrow]: AnalyticsEscrowValues;
        [AnalyticsProperties.Interest]: AnalyticsInterestValues;
    };
    /**
     * Triggered when users manually adjust an assignment within the
     * interactive preview.
     * https://app.amplitude.com/data/ynab/Production/events/main/latest/Adjusted%20Auto-Assign%20Amount
     */
    [AnalyticsEvents.Budget_AdjustedAutoAssignAmount]: {};
    /**
     * Client side. Triggered when a user manually assigns money on Mobile
     * or Web. Mobile: Clicking on the ’Done’ keypad CTA during Assign
     * Mode Web: Adding numbers on the ’Budget row’ or using the ’RTA
     * banner’ to assign.
     * https://app.amplitude.com/data/ynab/Production/events/main/latest/Assigned%20Money
     */
    [AnalyticsEvents.Budget_AssignedMoney]: {
        [AnalyticsProperties.AssignmentMonth]: AnalyticsAssignmentMonthValues;
        [AnalyticsProperties.Location]: AnalyticsLocationValues;
        [AnalyticsProperties.RtaState]: AnalyticsRtaStateValues;
        [AnalyticsProperties.UserJourneyState]: AnalyticsUserJourneyStateValues;
    };
    /**
     * Triggered when a user cancels (or, un-snoozes) a target in the
     * current month.
     * https://app.amplitude.com/data/ynab/Production/events/main/latest/Canceled%20Target%20Snooze
     */
    [AnalyticsEvents.Budget_CanceledTargetSnooze]: {
        [AnalyticsProperties.TargetSnoozeLocation]: AnalyticsTargetSnoozeLocationValues;
    };
    /**
     * Client side. Mobile only. Triggered when an Auto-Assign button is
     * clicked. Does not trigger when the assigned amount is updated --
     * e.g. if a user backs out at the confirmation modal step and doesn't
     * assign money, the event will still trigger. Renamed to “Clicked
     * View Auto Assign Options” in Amplitude.
     * https://app.amplitude.com/data/ynab/Production/events/main/latest/Clicked%20Auto%20Assign
     */
    [AnalyticsEvents.Budget_ClickedAutoAssign]: {
        [AnalyticsProperties.AutoAssignLocation]: AnalyticsAutoAssignLocationValues;
    };
    /**
     * Web: Clicked within the Notes field in the inspector.  Mobile:
     * Clicked within the Notes field through the budget details
     * drill-down.
     * https://app.amplitude.com/data/ynab/Production/events/main/latest/Clicked%20Category%20Notes
     */
    [AnalyticsEvents.Budget_ClickedCategoryNotes]: {
        [AnalyticsProperties.NoteInitialState]: AnalyticsNoteInitialStateValues;
    };
    /**
     * Clicked on a category within the 'Recent Moves' panel
     * https://app.amplitude.com/data/ynab/Production/events/main/latest/Clicked%20Category%20Recent%20Moves
     */
    [AnalyticsEvents.Budget_ClickedCategoryRecentMoves]: {};
    /**
     * Mobile: Triggered when a user taps on the option to collapse all
     * category groups on the budget view.  Web: Triggered when a user
     * clicks the collapse chevron to the right of the “Select All”
     * checkbox on the header row in the budget.
     * https://app.amplitude.com/data/ynab/Production/events/main/latest/Clicked%20Collapse%20All%20Groups
     */
    [AnalyticsEvents.Budget_ClickedCollapseAllGroups]: {};
    /**
     * Triggered when a user clicks the Cover Overspending CTA from the
     * CCPC inspector.
     * https://app.amplitude.com/data/ynab/Production/events/main/latest/Clicked%20Cover%20Credit%20Overspending
     */
    [AnalyticsEvents.Budget_ClickedCoverCreditOverspending]: {};
    /**
     * Triggered when a user clicks the Create a Debt Payoff Target
     * secondary action from the CCPC inspector
     * https://app.amplitude.com/data/ynab/Production/events/main/latest/Clicked%20Create%20Debt%20Payoff%20Target
     */
    [AnalyticsEvents.Budget_ClickedCreateDebtPayoffTarget]: {};
    /**
     * Mobile: Triggered when a user taps on the option to expand all
     * category groups on the budget view.  Web: Triggered when a user
     * clicks the expand chevron to the right of the “Select All” checkbox
     * on the header row in the budget.
     * https://app.amplitude.com/data/ynab/Production/events/main/latest/Clicked%20Expand%20All%20Groups
     */
    [AnalyticsEvents.Budget_ClickedExpandAllGroups]: {};
    /**
     * Clicked within the Notes field by the budget month.
     * https://app.amplitude.com/data/ynab/Production/events/main/latest/Clicked%20Month%20Notes
     */
    [AnalyticsEvents.Budget_ClickedMonthNotes]: {
        [AnalyticsProperties.NoteInitialState]: AnalyticsNoteInitialStateValues;
    };
    /**
     * Triggered when "Pair with a Loan" in the Monthly Debt target set up
     * is clicked, or when the "Pair with a Loan" button in the Inspector
     * (with an unpaired Monthly Debt target) is clicked.
     * https://app.amplitude.com/data/ynab/Production/events/main/latest/Clicked%20Pair%20Category%20with%20Loan
     */
    [AnalyticsEvents.Budget_ClickedPairCategoryWithLoan]: {};
    /**
     * Triggered when an Auto-Assign button is clicked. Does not trigger
     * when the assigned amount is updated -- e.g. if a user backs out at
     * the confirmation modal step and doesn't assign money, the event
     * will still trigger.      Examples:   A user taps one of the
     * Auto-Assign options in the keypad on mobile   A user clicks one of
     * the Auto-Assign options from the RtA Assign dropdown on web (and
     * confirms it if there is a confirmation preview modal)  A user
     * clicks one of the Auto-Assign options in the righthand inspector on
     * web (and confirms if there is a confirmation preview modal)
     * https://app.amplitude.com/data/ynab/Production/events/main/latest/Clicked%20Quick%20Budget
     */
    [AnalyticsEvents.Budget_ClickedQuickBudget]: {
        [AnalyticsProperties.AssignmentMonth]: AnalyticsAssignmentMonthValues;
        [AnalyticsProperties.AutoAssignLocation]: AnalyticsAutoAssignLocationValues;
        [AnalyticsProperties.QuickBudgetCount]: AnalyticsQuickBudgetCountValues;
        [AnalyticsProperties.QuickBudgetMethod]: AnalyticsQuickBudgetMethodValues;
        [AnalyticsProperties.QuickBudgetType]: AnalyticsQuickBudgetTypeValues;
    };
    /**
     * Triggered when a Quick Budget button is used for the first time
     * only.
     * https://app.amplitude.com/data/ynab/Production/events/main/latest/Clicked%20Quick%20Budget%20First%20Time
     */
    [AnalyticsEvents.Budget_ClickedQuickBudgetFirstTime]: {};
    /**
     * Triggered when a user clicks the View Transactions CTA from the
     * CCPC inspector
     * https://app.amplitude.com/data/ynab/Production/events/main/latest/Clicked%20View%20Uncategorized%20Credit%20Transactions
     */
    [AnalyticsEvents.Budget_ClickedViewUncategorizedCreditTransactions]: {};
    /**
     * Client side. User closes the Get a Month Ahead video.
     * https://app.amplitude.com/data/ynab/Production/events/main/latest/Closed%20Get%20A%20Month%20Ahead%20Video
     */
    [AnalyticsEvents.Budget_ClosedGetAMonthAheadVideo]: {
        [AnalyticsProperties.CloseMethod]: AnalyticsCloseMethodValues;
    };
    /**
     * Triggered when a user successfully advances from the Estimate
     * Expenses screen.
     * https://app.amplitude.com/data/ynab/Production/events/main/latest/Completed%20Add%20Expenses
     */
    [AnalyticsEvents.Budget_CompletedAddExpenses]: {};
    /**
     * Web: Triggered when overspending is covered using the popover
     * workflow.   Mobile: Triggered when overspending is covered with the
     * modal workflow. On iPad, tapping on a red or orange available pill
     * also triggers this event (tapping on a green pill to move money
     * does not).
     * https://app.amplitude.com/data/ynab/Production/events/main/latest/Covered%20Overspending
     */
    [AnalyticsEvents.Budget_CoveredOverspending]: {
        [AnalyticsProperties.Coverage]: AnalyticsCoverageValues;
    };
    /**
     * Triggered when a category is created.
     * https://app.amplitude.com/data/ynab/Production/events/main/latest/Created%20Category
     */
    [AnalyticsEvents.Budget_CreatedCategory]: {
        [AnalyticsProperties.Location]: AnalyticsLocationValues;
    };
    /**
     * Triggered when a category group is created.
     * https://app.amplitude.com/data/ynab/Production/events/main/latest/Created%20Category%20Group
     */
    [AnalyticsEvents.Budget_CreatedCategoryGroup]: {
        [AnalyticsProperties.Location]: AnalyticsLocationValues;
    };
    /**
     * Created a new custom focused view
     * https://app.amplitude.com/data/ynab/Production/events/main/latest/Created%20Focused%20View
     */
    [AnalyticsEvents.Budget_CreatedFocusedView]: {};
    /**
     * Evergreen: Triggered when a target is created through the
     * inspector.   Mobile: Triggered when a target is created through the
     * goal view  2022-02-22: Display name in Amplitude changed from
     * "Created Goal" to "Created Target"
     * https://app.amplitude.com/data/ynab/Production/events/main/latest/Created%20Goal
     */
    [AnalyticsEvents.Budget_CreatedGoal]: {
        [AnalyticsProperties.GoalType]: AnalyticsGoalTypeValues;
        [AnalyticsProperties.Location]: AnalyticsLocationValues;
        [AnalyticsProperties.NewTarget]: AnalyticsNewTargetValues;
        [AnalyticsProperties.SufficientIncome]: AnalyticsSufficientIncomeValues;
        [AnalyticsProperties.UserJourneyState]: AnalyticsUserJourneyStateValues;
    };
    /**
     * Triggered when a category is deleted.
     * https://app.amplitude.com/data/ynab/Production/events/main/latest/Deleted%20Category
     */
    [AnalyticsEvents.Budget_DeletedCategory]: {
        [AnalyticsProperties.Location]: AnalyticsLocationValues;
        [AnalyticsProperties.UserJourneyState]: AnalyticsUserJourneyStateValues;
    };
    /**
     * Triggered when a category group is deleted.
     * https://app.amplitude.com/data/ynab/Production/events/main/latest/Deleted%20Category%20Group
     */
    [AnalyticsEvents.Budget_DeletedCategoryGroup]: {
        [AnalyticsProperties.Location]: AnalyticsLocationValues;
        [AnalyticsProperties.UserJourneyState]: AnalyticsUserJourneyStateValues;
    };
    /**
     * Deleted a custom focused view
     * https://app.amplitude.com/data/ynab/Production/events/main/latest/Deleted%20Focused%20View
     */
    [AnalyticsEvents.Budget_DeletedFocusedView]: {};
    /**
     * https://app.amplitude.com/data/ynab/Production/events/main/latest/Deleted%20Goal
     */
    [AnalyticsEvents.Budget_DeletedGoal]: {
        [AnalyticsProperties.Location]: AnalyticsLocationValues;
        [AnalyticsProperties.SufficientIncome]: AnalyticsSufficientIncomeValues;
    };
    /**
     * Client side. Web only. User dismisses the help copy in the Assigned
     * in Future Months section of the Inspector.
     * https://app.amplitude.com/data/ynab/Production/events/main/latest/Dismissed%20Get%20A%20Month%20Ahead%20Help%20Prompt
     */
    [AnalyticsEvents.Budget_DismissedGetAMonthAheadHelpPrompt]: {};
    /**
     * Evergreen: Triggered any time a user edits a field in the debt
     * payment target
     * https://app.amplitude.com/data/ynab/Production/events/main/latest/Edited%20Debt%20Target
     */
    [AnalyticsEvents.Budget_EditedDebtTarget]: {
        [AnalyticsProperties.DebtTargetInputType]: AnalyticsDebtTargetInputTypeValues;
        [AnalyticsProperties.PairedCategory]: AnalyticsPairedCategoryValues;
    };
    /**
     * Triggered when the transition to the Assign Mode begins.
     * https://app.amplitude.com/data/ynab/Production/events/main/latest/Entered%20Auto-Assign%20Preview
     */
    [AnalyticsEvents.Budget_EnteredAutoAssignPreview]: {
        [AnalyticsProperties.Location]: AnalyticsLocationValues;
        [AnalyticsProperties.UserJourneyState]: AnalyticsUserJourneyStateValues;
    };
    /**
     * Client side. Mobile only. Triggered when a user enters or updates
     * expected income on the Edit Plan screen.
     * https://app.amplitude.com/data/ynab/Production/events/main/latest/Entered%20Expected%20Income
     */
    [AnalyticsEvents.Budget_EnteredExpectedIncome]: {
        [AnalyticsProperties.SufficientIncome]: AnalyticsSufficientIncomeValues;
    };
    /**
     * Triggered when a user clicks on Current Balance or Available for
     * Payment from the CCPC inspector
     * https://app.amplitude.com/data/ynab/Production/events/main/latest/Expanded%20Credit%20Card%20Payment%20Detail
     */
    [AnalyticsEvents.Budget_ExpandedCreditCardPaymentDetail]: {
        [AnalyticsProperties.Detail]: AnalyticsDetailValues;
    };
    /**
     * Triggered when a user applies a filter to Recent Moves.
     * https://app.amplitude.com/data/ynab/Production/events/main/latest/Filtered%20Recent%20Moves
     */
    [AnalyticsEvents.Budget_FilteredRecentMoves]: {
        [AnalyticsProperties.MovesTypeFilter]: AnalyticsMovesTypeFilterValues;
    };
    /**
     * Triggered when user clicks OK (i.e. moves money or covers
     * overspending) from the TBB banner/bubble.
     * https://app.amplitude.com/data/ynab/Production/events/main/latest/Finished%20Move%20Money%20from%20TBB
     */
    [AnalyticsEvents.Budget_FinishedMoveMoneyFromTbb]: {
        [AnalyticsProperties.FromTbbOrToTbb]: AnalyticsFromTbbOrToTbbValues;
    };
    /**
     * Triggered whenever a user goes from a negative To be Budgeted
     * amount to a positive budgeted amount TBB amount. This event
     * indicates a user understands the method  *The event is not
     * triggered when a new account is added because it's a server side
     * change.
     * https://app.amplitude.com/data/ynab/Production/events/main/latest/Fixed%20Overbudgeting
     */
    [AnalyticsEvents.Budget_FixedOverbudgeting]: {};
    /**
     * Triggered when a category is hidden.
     * https://app.amplitude.com/data/ynab/Production/events/main/latest/Hid%20Category
     */
    [AnalyticsEvents.Budget_HidCategory]: {};
    /**
     * Triggered when a category group is hidden.
     * https://app.amplitude.com/data/ynab/Production/events/main/latest/Hid%20Category%20Group
     */
    [AnalyticsEvents.Budget_HidCategoryGroup]: {};
    /**
     * Client side. Recorded when a user imports a Template into their
     * budget (feature adoption).
     * https://app.amplitude.com/data/ynab/Production/events/main/latest/Imported%20Category%20Template
     */
    [AnalyticsEvents.Budget_ImportedCategoryTemplate]: {
        [AnalyticsProperties.CategoryTemplate]: AnalyticsCategoryTemplateValues;
        [AnalyticsProperties.ImportedSelectCategories]: AnalyticsImportedSelectCategoriesValues;
    };
    /**
     * Evergreen: Triggered by "Move Money" popover menu.   Mobile:
     * Triggered by the "Move Money" action in the keypad toolbar.
     * https://app.amplitude.com/data/ynab/Production/events/main/latest/Moved%20Money
     */
    [AnalyticsEvents.Budget_MovedMoney]: {
        [AnalyticsProperties.FromCategoryType]: AnalyticsFromCategoryTypeValues;
        [AnalyticsProperties.ToCategoryType]: AnalyticsToCategoryTypeValues;
    };
    /**
     * Client side. User navigates to a future month.
     * https://app.amplitude.com/data/ynab/Production/events/main/latest/Navigated%20To%20Future%20Month
     */
    [AnalyticsEvents.Budget_NavigatedToFutureMonth]: {
        [AnalyticsProperties.NavigationSource]: AnalyticsNavigationSourceValues;
    };
    /**
     * Clicked on the button 'Recent Moves'
     * https://app.amplitude.com/data/ynab/Production/events/main/latest/Opened%20Recent%20Moves
     */
    [AnalyticsEvents.Budget_OpenedRecentMoves]: {};
    /**
     * Triggered whenever a user goes from a positive To be Budgeted
     * amount to a negative budgeted amount TBB amount. This event
     * indicates a user does not understand the YNAB method.
     * https://app.amplitude.com/data/ynab/Production/events/main/latest/Overbudgeted
     */
    [AnalyticsEvents.Budget_Overbudgeted]: {};
    /**
     * Triggered at the end of the flow pairing an account to a category
     * (when started from the category)
     * https://app.amplitude.com/data/ynab/Production/events/main/latest/Paired%20Category%20with%20Loan
     */
    [AnalyticsEvents.Budget_PairedCategoryWithLoan]: {};
    /**
     * Changed the display order of focused views
     * https://app.amplitude.com/data/ynab/Production/events/main/latest/Reordered%20Focused%20Views
     */
    [AnalyticsEvents.Budget_ReorderedFocusedViews]: {};
    /**
     * Client side, Mobile only.  Triggered when a user clicks on the
     * ’saved assignments’ on assign mode.
     * https://app.amplitude.com/data/ynab/Production/events/main/latest/Saved%20Assignments
     */
    [AnalyticsEvents.Budget_SavedAssignments]: {
        [AnalyticsProperties.PercentageOfCategoriesThatHaveTargets]: AnalyticsPercentageOfCategoriesThatHaveTargetsValues;
        [AnalyticsProperties.PercentageOfCategoriesWhichWereAssignedTo]: AnalyticsPercentageOfCategoriesWhichWereAssignedToValues;
        [AnalyticsProperties.PercentageOfRemainingInRta]: AnalyticsPercentageOfRemainingInRtaValues;
    };
    /**
     * Selected a focused view of the budget
     * https://app.amplitude.com/data/ynab/Production/events/main/latest/Selected%20Focused%20View
     */
    [AnalyticsEvents.Budget_SelectedFocusedView]: {
        [AnalyticsProperties.ViewType]: AnalyticsViewTypeValues;
    };
    /**
     * Triggered when a user restores a category from the hidden
     * categories list.
     * https://app.amplitude.com/data/ynab/Production/events/main/latest/Showed%20Category
     */
    [AnalyticsEvents.Budget_ShowedCategory]: {};
    /**
     * Triggered when a user restores a category group from the hidden
     * categories list. This feature is not available on Android.
     * https://app.amplitude.com/data/ynab/Production/events/main/latest/Showed%20Category%20Group
     */
    [AnalyticsEvents.Budget_ShowedCategoryGroup]: {};
    /**
     * Triggered when a user chooses to snooze the target alert in the
     * current month.
     * https://app.amplitude.com/data/ynab/Production/events/main/latest/Snoozed%20Target
     */
    [AnalyticsEvents.Budget_SnoozedTarget]: {
        [AnalyticsProperties.TargetSnoozeLocation]: AnalyticsTargetSnoozeLocationValues;
    };
    /**
     * Client side, Web only. Triggered when a user that doesn't have a
     * target for a specific category, clicks on ’create target’ CTA.
     * https://app.amplitude.com/data/ynab/Production/events/main/latest/Started%20Create%20Target
     */
    [AnalyticsEvents.Budget_StartedCreateTarget]: {
        [AnalyticsProperties.UserJourneyState]: AnalyticsUserJourneyStateValues;
    };
    /**
     * Client side. User starts the Get a Month Ahead video.
     * https://app.amplitude.com/data/ynab/Production/events/main/latest/Started%20Get%20A%20Month%20Ahead%20Video
     */
    [AnalyticsEvents.Budget_StartedGetAMonthAheadVideo]: {
        [AnalyticsProperties.StartSource]: AnalyticsStartSourceValues;
    };
    /**
     * Triggered when user clicks on the Record Payment button in the
     * inspector
     * https://app.amplitude.com/data/ynab/Production/events/main/latest/Started%20Record%20Payment%20from%20Inspector
     */
    [AnalyticsEvents.Budget_StartedRecordPaymentFromInspector]: {};
    /**
     * User selects a future month button in the Assigned in Future Months
     * section. On web, this navigates to the future month. On mobile,
     * this opens Assign Mode.
     * https://app.amplitude.com/data/ynab/Production/events/main/latest/Tapped%20Future%20Month%20Button
     */
    [AnalyticsEvents.Budget_TappedFutureMonthButton]: {};
    /**
     * Triggered when a user applies a change to the visibility of budget
     * bars. They can either turn them on (default) or off (compact).
     * https://app.amplitude.com/data/ynab/Production/events/main/latest/Toggled%20Progress%20Bars
     */
    [AnalyticsEvents.Budget_ToggledProgressBars]: {
        [AnalyticsProperties.On_off]: AnalyticsOn_offValues;
    };
    /**
     * Client side, Mobile only. Triggered when a user clicks on the
     * ’keypad accessory’ within the assign mode keypad.
     * https://app.amplitude.com/data/ynab/Production/events/main/latest/Used%20Assign%20Keypad%20Accessory
     */
    [AnalyticsEvents.Budget_UsedAssignKeypadAccessory]: {};
    /**
     * Clicked on the history icon within a budget category to view
     * category moves
     * https://app.amplitude.com/data/ynab/Production/events/main/latest/Viewed%20Category%20Moves
     */
    [AnalyticsEvents.Budget_ViewedCategoryMoves]: {
        [AnalyticsProperties.MonthDelta]: AnalyticsMonthDeltaValues;
    };
    /**
     * Triggered when a user views the CCPC details view. On mobile, this
     * is tapping a CCPC to open the new inspector. On web, this is
     * selecting a CCPC and viewing the inspector.
     * https://app.amplitude.com/data/ynab/Production/events/main/latest/Viewed%20Credit%20Card%20Payment%20Inspector
     */
    [AnalyticsEvents.Budget_ViewedCreditCardPaymentInspector]: {
        [AnalyticsProperties.CreditCardPaymentStatus]: AnalyticsCreditCardPaymentStatusValues;
        [AnalyticsProperties.MultipleWarnings]: AnalyticsMultipleWarningsValues;
    };
    /**
     * Triggered when a first-time user tries to advance from the Estimate
     * Expenses screen with no expenses set.
     * https://app.amplitude.com/data/ynab/Production/events/main/latest/Viewed%20No%20Expenses%20Added%20Error
     */
    [AnalyticsEvents.Budget_ViewedNoExpensesAddedError]: {};
    /**
     * Triggered when a user clicks the "View to Be Budgeted Breakdown"
     * link from the TBB bubble.
     * https://app.amplitude.com/data/ynab/Production/events/main/latest/Viewed%20To%20be%20Budgeted%20Breakdown
     */
    [AnalyticsEvents.Budget_ViewedToBeBudgetedBreakdown]: {};
    /**
     * Triggered when a user's Age of Money populates with a value higher
     * than 0 for the first time.
     * https://app.amplitude.com/data/ynab/Production/events/main/latest/Aged%20Money%20First%20Time
     */
    [AnalyticsEvents.General_AgedMoneyFirstTime]: {};
    /**
     * Triggered when a parter clicks the Copy button for the group invite
     * https://app.amplitude.com/data/ynab/Production/events/main/latest/Clicked%20Copy%20Group%20Invite
     */
    [AnalyticsEvents.General_ClickedCopyGroupInvite]: {};
    /**
     * Triggered when a customer clicks the multi-select checkbox at the
     * top of a table (e.g. budget, account, manage payees).
     * https://app.amplitude.com/data/ynab/Production/events/main/latest/Clicked%20Multi-Select%20Checkbox
     */
    [AnalyticsEvents.General_ClickedMultiSelectCheckbox]: {
        [AnalyticsProperties.Action]: AnalyticsActionValues;
        [AnalyticsProperties.Location]: AnalyticsLocationValues;
    };
    /**
     * Web only. Client side. Triggered when a user clicks on the Referral
     * Program button.
     * https://app.amplitude.com/data/ynab/Production/events/main/latest/Clicked%20Referral%20Program%20Button
     */
    [AnalyticsEvents.General_ClickedReferralProgramButton]: {
        [AnalyticsProperties.Location]: AnalyticsLocationValues;
    };
    /**
     * User selects "New Budget" from the settings menu, then completes
     * the action with "Create Budget."
     * https://app.amplitude.com/data/ynab/Production/events/main/latest/Created%20New%20Budget
     */
    [AnalyticsEvents.General_CreatedNewBudget]: {};
    /**
     * Triggered when a partner clicks the button to create a new invite
     * link
     * https://app.amplitude.com/data/ynab/Production/events/main/latest/Created%20New%20Group%20Invite
     */
    [AnalyticsEvents.General_CreatedNewGroupInvite]: {};
    /**
     * Triggered when a customer adds an overdraft budget account (not a
     * credit card) and RTA is negative as a result.
     * https://app.amplitude.com/data/ynab/Production/events/main/latest/Entered%20Overdraft%20State
     */
    [AnalyticsEvents.General_EnteredOverdraftState]: {};
    /**
     * Triggered when a user exports the full budget (categories and
     * transactions).
     * https://app.amplitude.com/data/ynab/Production/events/main/latest/Exported%20Budget
     */
    [AnalyticsEvents.General_ExportedBudget]: {};
    /**
     * Triggered when a customer exports a report.
     * https://app.amplitude.com/data/ynab/Production/events/main/latest/Exported%20Report
     */
    [AnalyticsEvents.General_ExportedReport]: {
        [AnalyticsProperties.ReportType]: AnalyticsReportTypeValues;
    };
    /**
     * Triggered when a customer exports a selection of transactions.
     * https://app.amplitude.com/data/ynab/Production/events/main/latest/Exported%20Transactions
     */
    [AnalyticsEvents.General_ExportedTransactions]: {
        [AnalyticsProperties.Location]: AnalyticsLocationValues;
    };
    /**
     * Triggered when a user logs in.
     * https://app.amplitude.com/data/ynab/Production/events/main/latest/Logged%20In
     */
    [AnalyticsEvents.General_LoggedIn]: {
        [AnalyticsProperties.LogInMethod]: AnalyticsLogInMethodValues;
    };
    /**
     * Triggered when a user logs out.
     * https://app.amplitude.com/data/ynab/Production/events/main/latest/Logged%20Out
     */
    [AnalyticsEvents.General_LoggedOut]: {};
    /**
     * User selects "Make a Fresh Start" from the settings menu, then
     * completes the action with "Continue."
     * https://app.amplitude.com/data/ynab/Production/events/main/latest/Made%20Fresh%20Start
     */
    [AnalyticsEvents.General_MadeFreshStart]: {
        [AnalyticsProperties.StopImportingTransactionsSelection]: AnalyticsStopImportingTransactionsSelectionValues;
    };
    /**
     * Triggered when finished opening a budget.
     * https://app.amplitude.com/data/ynab/Production/events/main/latest/Opened%20Budget
     */
    [AnalyticsEvents.General_OpenedBudget]: {};
    /**
     * Triggered when a partner removes a member from their Financial
     * Wellness by YNAB plan
     * https://app.amplitude.com/data/ynab/Production/events/main/latest/Removed%20Group%20Member
     */
    [AnalyticsEvents.General_RemovedGroupMember]: {};
    /**
     * Triggered when a partner clicks to view one of the resources
     * available on the Financial Wellness by YNAB dashboard
     * https://app.amplitude.com/data/ynab/Production/events/main/latest/Viewed%20Group%20Resources
     */
    [AnalyticsEvents.Help_ViewedGroupResources]: {
        [AnalyticsProperties.Resource]: AnalyticsResourceValues;
    };
    /**
     * This event tracks the closure of the Plaid widget.
     * https://app.amplitude.com/data/ynab/Production/events/main/latest/Closed%20Plaid%20Widget
     */
    [AnalyticsEvents.Import_ClosedPlaidWidget]: {
        [AnalyticsProperties.PlaidAccountsLinkedCount]: AnalyticsPlaidAccountsLinkedCountValues;
        [AnalyticsProperties.PlaidChosePreselectedInstitution]: AnalyticsPlaidChosePreselectedInstitutionValues;
        [AnalyticsProperties.PlaidExitReasonSummary]: AnalyticsPlaidExitReasonSummaryValues;
        [AnalyticsProperties.PlaidPhoneField]: AnalyticsPlaidPhoneFieldValues;
        [AnalyticsProperties.PlaidSavedAccountsScreen]: AnalyticsPlaidSavedAccountsScreenValues;
        [AnalyticsProperties.PlaidSavedInstitutionsScreen]: AnalyticsPlaidSavedInstitutionsScreenValues;
        [AnalyticsProperties.PlaidWidgetOpenDuration]: AnalyticsPlaidWidgetOpenDurationValues;
    };
    /**
     * Triggered when a user completes the import FBI process.
     * https://app.amplitude.com/data/ynab/Production/events/main/latest/Imported%20FBI%20Transactions
     */
    [AnalyticsEvents.Import_ImportedFbiTransactions]: {
        [AnalyticsProperties.FbiError]: AnalyticsFbiErrorValues;
        [AnalyticsProperties.FileType]: AnalyticsFileTypeValues;
        [AnalyticsProperties.Location]: AnalyticsLocationValues;
    };
    /**
     * This event tracks when a user views the Plaid widget.
     * https://app.amplitude.com/data/ynab/Production/events/main/latest/Viewed%20Plaid%20Widget
     */
    [AnalyticsEvents.Import_ViewedPlaidWidget]: {};
    /**
     * Triggered when a user answers a single-select question or clicks
     * the CTA “Continue” from any multi-select question or interstitial
     * screens.
     * https://app.amplitude.com/data/ynab/Production/events/main/latest/Advanced%20Category%20Wizard%20Step
     */
    [AnalyticsEvents.Onboarding_AdvancedCategoryWizardStep]: {
        [AnalyticsProperties.CategoryWizardResponse]: AnalyticsCategoryWizardResponseValues;
        [AnalyticsProperties.ResponsePosition]: AnalyticsResponsePositionValues;
        [AnalyticsProperties.WizardStep]: AnalyticsWizardStepValues;
    };
    /**
     * Only web: Triggered when a user advances in the Mint Importer Flow.
     * Note that "Clicked Migrate Flow" is a separate event, but part of
     * the user flow.
     * https://app.amplitude.com/data/ynab/Production/events/main/latest/Advanced%20Mint%20Importer%20Step
     */
    [AnalyticsEvents.Onboarding_AdvancedMintImporterStep]: {
        [AnalyticsProperties.ImporterStep]: AnalyticsImporterStepValues;
        [AnalyticsProperties.MigrationPath]: AnalyticsMigrationPathValues;
        [AnalyticsProperties.OnboardingPath]: AnalyticsOnboardingPathValues;
    };
    /**
     * Triggered when the user chooses to assign their savings dollars to
     * expenses (not create a new category).
     * https://app.amplitude.com/data/ynab/Production/events/main/latest/Assigned%20Savings%20to%20Expenses
     */
    [AnalyticsEvents.Onboarding_AssignedSavingsToExpenses]: {};
    /**
     * Only web: Users clicks the "Migrate from" option in the settings
     * menu.
     * https://app.amplitude.com/data/ynab/Production/events/main/latest/Clicked%20Migrate%20From
     */
    [AnalyticsEvents.Onboarding_ClickedMigrateFrom]: {
        [AnalyticsProperties.MigrationSource]: AnalyticsMigrationSourceValues;
    };
    /**
     * Triggered when users closes the intro screen.
     * https://app.amplitude.com/data/ynab/Production/events/main/latest/Closed%20Intro
     */
    [AnalyticsEvents.Onboarding_ClosedIntro]: {};
    /**
     * Triggered when user closes the outro.
     * https://app.amplitude.com/data/ynab/Production/events/main/latest/Closed%20Outro
     */
    [AnalyticsEvents.Onboarding_ClosedOutro]: {};
    /**
     * Triggered when a user completes the Add Account onboarding step.
     * https://app.amplitude.com/data/ynab/Production/events/main/latest/Completed%20Add%20Account%20Step
     */
    [AnalyticsEvents.Onboarding_CompletedAddAccountStep]: {};
    /**
     * Triggered when the user clicks on the option to create savings
     * category (within prompt).
     * https://app.amplitude.com/data/ynab/Production/events/main/latest/Created%20Savings%20Category
     */
    [AnalyticsEvents.Onboarding_CreatedSavingsCategory]: {};
    /**
     * Triggered when user clicks "Done Estimating Expenses" button from
     * the FTUE banner on web.
     * https://app.amplitude.com/data/ynab/Production/events/main/latest/Done%20Estimating%20Expenses
     */
    [AnalyticsEvents.Onboarding_DoneEstimatingExpenses]: {};
    /**
     * Triggered when users land on the first CW step
     * https://app.amplitude.com/data/ynab/Production/events/main/latest/Launched%20Category%20Wizard
     */
    [AnalyticsEvents.Onboarding_LaunchedCategoryWizard]: {};
    /**
     * Triggered when user clicks "Finish Your Budget" banner to open the
     * outro.
     * https://app.amplitude.com/data/ynab/Production/events/main/latest/Opened%20Outro
     */
    [AnalyticsEvents.Onboarding_OpenedOutro]: {
        [AnalyticsProperties.OutroState]: AnalyticsOutroStateValues;
    };
    /**
     * Triggered when a user clicks on any answer option in the "How do
     * you feel about your finances today?" screen.
     * https://app.amplitude.com/data/ynab/Production/events/main/latest/Rated%20Feeling
     */
    [AnalyticsEvents.Onboarding_RatedFeeling]: {
        [AnalyticsProperties.FeelingRate]: AnalyticsFeelingRateValues;
    };
    /**
     * Triggered when a user clicks "Start Over" in the Category Wizard.
     * https://app.amplitude.com/data/ynab/Production/events/main/latest/Restarted%20Category%20Wizard
     */
    [AnalyticsEvents.Onboarding_RestartedCategoryWizard]: {};
    /**
     * Client side, Mobile only. Onboarding event. Triggered when a user
     * clicks on the CTA to continue the targets step.
     * https://app.amplitude.com/data/ynab/Production/events/main/latest/Returned%20to%20Setting%20Targets%20Onboarding
     */
    [AnalyticsEvents.Onboarding_ReturnedToSettingTargetsOnboarding]: {};
    /**
     * Triggered when a user clicks on any answer option in the "Who do
     * you spend money on?" screen.  Fired when they click sign up and
     * subscribe.
     * https://app.amplitude.com/data/ynab/Production/events/main/latest/Selected%20Spending%20Recipients
     */
    [AnalyticsEvents.Onboarding_SelectedSpendingRecipients]: {
        [AnalyticsProperties.SpendingRecipientType]: AnalyticsSpendingRecipientTypeValues;
    };
    /**
     * After dismissing the Add Accounts widget without adding a budget
     * account, this triggers when the user then clicks the "Skip" button
     * on the subsequent "Add Checking, Savings or Cash Account" modal
     * that appears.
     * https://app.amplitude.com/data/ynab/Production/events/main/latest/Skipped%20Adding%20On-Budget%20Account
     */
    [AnalyticsEvents.Onboarding_SkippedAddingOnBudgetAccount]: {};
    /**
     * Triggered when a user clicks Skip Tutorial on Evergreen
     * https://app.amplitude.com/data/ynab/Production/events/main/latest/Skipped%20Onboarding
     */
    [AnalyticsEvents.Onboarding_SkippedOnboarding]: {};
    /**
     * Client side, Mobile only. Onboarding event. Triggered when a user
     * clicks on the CTA to skip the targets step.
     * https://app.amplitude.com/data/ynab/Production/events/main/latest/Skipped%20Setting%20Targets%20Onboarding
     */
    [AnalyticsEvents.Onboarding_SkippedSettingTargetsOnboarding]: {};
    /**
     * Triggered when a user taps “Add Your Bank Accounts” in the mobile
     * onboarding flow.  On web, triggered when a user clicks "Continue"
     * from within the FTUE Step 1 slide.
     * https://app.amplitude.com/data/ynab/Production/events/main/latest/Started%20Add%20Account%20Step
     */
    [AnalyticsEvents.Onboarding_StartedAddAccountStep]: {};
    /**
     * Triggered on web when a user clicks the "Continue" button from
     * within the FTUE Step 2 (add accounts) slide.
     * https://app.amplitude.com/data/ynab/Production/events/main/latest/Started%20Assign%20Money%20Step
     */
    [AnalyticsEvents.Onboarding_StartedAssignMoneyStep]: {};
    /**
     * Triggered when a user lands on the first screen of the FTUE
     * onboarding experience but before they click the button to initiate
     * the flow.
     * https://app.amplitude.com/data/ynab/Production/events/main/latest/Started%20Web%20FTUE%20Onboarding
     */
    [AnalyticsEvents.Onboarding_StartedWebFtueOnboarding]: {};
    /**
     * Evergreen: Triggered when a user views activity through the
     * popover.   Mobile: Triggered when a user views activity through the
     * budget details drill-down.   iOS only: Triggered when a user views
     * activity through the budget row context menu.
     * https://app.amplitude.com/data/ynab/Production/events/main/latest/Viewed%20Activity
     */
    [AnalyticsEvents.PageView_ViewedActivity]: {
        [AnalyticsProperties.ViewedActivityMethod]: AnalyticsViewedActivityMethodValues;
    };
    /**
     * Triggered when the Age of Money report is viewed.
     * https://app.amplitude.com/data/ynab/Production/events/main/latest/Viewed%20Age%20of%20Money%20Report
     */
    [AnalyticsEvents.PageView_ViewedAgeOfMoneyReport]: {};
    /**
     * Triggered when All Accounts is viewed.
     * https://app.amplitude.com/data/ynab/Production/events/main/latest/Viewed%20All%20Accounts
     */
    [AnalyticsEvents.PageView_ViewedAllAccounts]: {};
    /**
     * Evergreen: Triggered when a user views category activity through
     * the popover.   Mobile: Triggered when a user views category
     * activity through the budget details drill-down.   iOS only:
     * Triggered when a user views category activity through the budget
     * row context menu.
     * https://app.amplitude.com/data/ynab/Production/events/main/latest/Viewed%20Category%20Activity
     */
    [AnalyticsEvents.PageView_ViewedCategoryActivity]: {
        [AnalyticsProperties.ViewedActivityMethod]: AnalyticsViewedActivityMethodValues;
    };
    /**
     * Triggered when a user selects to open Family Settings within the
     * Settings menu. Note: this is one event for both Plan Manager and
     * members even though the selection text is different.
     * https://app.amplitude.com/data/ynab/Production/events/main/latest/Viewed%20Family%20Settings
     */
    [AnalyticsEvents.PageView_ViewedFamilySettings]: {};
    /**
     * Triggered when a partner navigates to the Dashboard page
     * https://app.amplitude.com/data/ynab/Production/events/main/latest/Viewed%20Group%20Dashboard
     */
    [AnalyticsEvents.PageView_ViewedGroupDashboard]: {};
    /**
     * Triggered when a partner navigates to the Members page
     * https://app.amplitude.com/data/ynab/Production/events/main/latest/Viewed%20Group%20Members
     */
    [AnalyticsEvents.PageView_ViewedGroupMembers]: {};
    /**
     * Triggered when a Financial Wellness group admin views the
     * Subscription page
     * https://app.amplitude.com/data/ynab/Production/events/main/latest/Viewed%20Group%20Subscription
     */
    [AnalyticsEvents.PageView_ViewedGroupSubscription]: {};
    /**
     * Triggered when a user accesses the Income v Expense report
     * https://app.amplitude.com/data/ynab/Production/events/main/latest/Viewed%20Income%20v%20Expense%20Report
     */
    [AnalyticsEvents.PageView_ViewedIncomeVExpenseReport]: {};
    /**
     * Triggered when a customer opens up the Manage Payees modal.
     * https://app.amplitude.com/data/ynab/Production/events/main/latest/Viewed%20Manage%20Payees
     */
    [AnalyticsEvents.PageView_ViewedManagePayees]: {
        [AnalyticsProperties.Location]: AnalyticsLocationValues;
    };
    /**
     * Triggered when a user accesses the Net Worth report
     * https://app.amplitude.com/data/ynab/Production/events/main/latest/Viewed%20Net%20Worth%20Report
     */
    [AnalyticsEvents.PageView_ViewedNetWorthReport]: {};
    /**
     * Triggered when a user views any of the Reconciliation popovers
     * https://app.amplitude.com/data/ynab/Production/events/main/latest/Viewed%20Reconciliation%20Popover
     */
    [AnalyticsEvents.PageView_ViewedReconciliationPopover]: {
        [AnalyticsProperties.DirectImportCurrentBalanceMatch]: AnalyticsDirectImportCurrentBalanceMatchValues;
        [AnalyticsProperties.FinancialInstitution]: AnalyticsFinancialInstitutionValues;
        [AnalyticsProperties.ReconciliationStep]: AnalyticsReconciliationStepValues;
    };
    /**
     * Triggered when a user visits the referral program page
     * https://app.amplitude.com/data/ynab/Production/events/main/latest/Viewed%20Referral%20Program
     */
    [AnalyticsEvents.PageView_ViewedReferralProgram]: {};
    /**
     * Client side. Triggered when Reflect tab root is viewed.
     * https://app.amplitude.com/data/ynab/Production/events/main/latest/Viewed%20Reflect%20Tab
     */
    [AnalyticsEvents.PageView_ViewedReflectTab]: {};
    /**
     * Triggered when Settings is viewed.
     * https://app.amplitude.com/data/ynab/Production/events/main/latest/Viewed%20Settings
     */
    [AnalyticsEvents.PageView_ViewedSettings]: {};
    /**
     * Client side. Triggered when Spending Breakdown details are viewed.
     * https://app.amplitude.com/data/ynab/Production/events/main/latest/Viewed%20Spending%20Breakdown
     */
    [AnalyticsEvents.PageView_ViewedSpendingBreakdown]: {};
    /**
     * Triggered when a user accesses the Spending report
     * https://app.amplitude.com/data/ynab/Production/events/main/latest/Viewed%20Spending%20Report
     */
    [AnalyticsEvents.PageView_ViewedSpendingReport]: {};
    /**
     * Triggered when a customer navigates to the subscription page in
     * Account Settings
     * https://app.amplitude.com/data/ynab/Production/events/main/latest/Viewed%20Subscribe
     */
    [AnalyticsEvents.PageView_ViewedSubscribe]: {};
    /**
     * Triggered when a user applies a filter to a report
     * https://app.amplitude.com/data/ynab/Production/events/main/latest/Filtered%20Report
     */
    [AnalyticsEvents.Reports_FilteredReport]: {
        [AnalyticsProperties.AccountsFilter]: AnalyticsAccountsFilterValues;
        [AnalyticsProperties.CategoriesFilter]: AnalyticsCategoriesFilterValues;
        [AnalyticsProperties.DateFilter]: AnalyticsDateFilterValues;
        [AnalyticsProperties.ReflectionPath]: AnalyticsReflectionPathValues;
    };
    /**
     * Marketing: Triggered when clicking Sign Up on the marketing site.
     * Evergreen: Triggered when a user clicks Sign Up Today.  Mobile:
     * Triggered when a user taps “Try YNAB for Free”.
     * https://app.amplitude.com/data/ynab/Production/events/main/latest/Clicked%20Sign%20Up
     */
    [AnalyticsEvents.SignUp_ClickedSignUp]: {};
    /**
     * This event is tied to a client-side action which means it could be
     * dropped by ad blockers. Please update any charts to use the more
     * accurate ‘Created User’ event. The “Created User” event loses
     * context (locale, device, etc.) but is directly tied to the user
     * registration event on the server.  Triggered when a user agrees to
     * the terms of service (after they submit an email and password).
     * https://app.amplitude.com/data/ynab/Production/events/main/latest/Completed%20Sign%20Up
     */
    [AnalyticsEvents.SignUp_CompletedSignUp]: {
        [AnalyticsProperties.SignUpMethod]: AnalyticsSignUpMethodValues;
        [AnalyticsProperties.UtmCampaign]: AnalyticsUtmCampaignValues;
        [AnalyticsProperties.UtmContent]: AnalyticsUtmContentValues;
        [AnalyticsProperties.UtmMedium]: AnalyticsUtmMediumValues;
        [AnalyticsProperties.UtmSource]: AnalyticsUtmSourceValues;
    };
    /**
     * Triggered on the server when a user is created via a signup.  This
     * is a server side event so platform is not reported the traditional
     * way. To see a platform breakdown, use the custom event property
     * called Sign Up Source.
     * https://app.amplitude.com/data/ynab/Production/events/main/latest/Created%20User
     */
    [AnalyticsEvents.SignUp_CreatedUser]: {
        [AnalyticsProperties.SignUpMethod]: AnalyticsSignUpMethodValues;
        [AnalyticsProperties.SignUpSource]: AnalyticsSignUpSourceValues;
    };
    /**
     * Triggered when a user sends a referral invite
     * https://app.amplitude.com/data/ynab/Production/events/main/latest/Sent%20Referral%20Invite
     */
    [AnalyticsEvents.Social_SentReferralInvite]: {
        [AnalyticsProperties.ReferralMethod]: AnalyticsReferralMethodValues;
    };
    /**
     * Triggered when a subscription is gifted.
     * https://app.amplitude.com/data/ynab/Production/events/main/latest/Gifted%20Subscription
     */
    [AnalyticsEvents.Subscription_GiftedSubscription]: {};
    /**
     * Triggered when a user submits the exit survey form asking them why
     * they cancelled.
     * https://app.amplitude.com/data/ynab/Production/events/main/latest/Selected%20Cancellation%20Reason
     */
    [AnalyticsEvents.Subscription_SelectedCancellationReason]: {
        [AnalyticsProperties.ExitSurveyOption]: AnalyticsExitSurveyOptionValues;
    };
    /**
     * Triggered when a Financial Wellness group admin clicks the button
     * to contact us about Pay Up Front
     * https://app.amplitude.com/data/ynab/Production/events/main/latest/Selected%20Pay%20Up%20Front
     */
    [AnalyticsEvents.Subscription_SelectedPayUpFront]: {};
    /**
     * Added June 2019. We are triggering this event when an iOS user
     * selects a plan in the onboarding subscription screen.   In Feb
     * 2022, we also added this event to Android, triggered when a Google
     * user selects a plan in the plan selection screen.
     * https://app.amplitude.com/data/ynab/Production/events/main/latest/Started%20Trial
     */
    [AnalyticsEvents.Subscription_StartedTrial]: {
        [AnalyticsProperties.SubscriptionPlan]: AnalyticsSubscriptionPlanValues;
        [AnalyticsProperties.SubscriptionProvider]: AnalyticsSubscriptionProviderValues;
    };
    /**
     * Triggered when a customer views the expiration modal
     * https://app.amplitude.com/data/ynab/Production/events/main/latest/Viewed%20Expiration%20Message
     */
    [AnalyticsEvents.Subscription_ViewedExpirationMessage]: {};
    /**
     * Client side. Triggered when user clicks to open chat widget on web
     * or mobile apps. Properties allow us to determine what app screen
     * they are on, which chatbot was selected and why, and how many open
     * conversations they have along with their IDs.
     * https://app.amplitude.com/data/ynab/Production/events/main/latest/Clicked%20Chat%20Widget
     */
    [AnalyticsEvents.Support_ClickedChatWidget]: {
        [AnalyticsProperties.OpenConversationCount]: AnalyticsOpenConversationCountValues;
        [AnalyticsProperties.OpenConversationsIds]: AnalyticsOpenConversationsIdsValues;
        [AnalyticsProperties.PageName]: AnalyticsPageNameValues;
        [AnalyticsProperties.WidgetReason]: AnalyticsWidgetReasonValues;
        [AnalyticsProperties.WidgetSelected]: AnalyticsWidgetSelectedValues;
    };
    /**
     * Triggered when a user clicks the external link to view order
     * history via the Bank Import Details flyout.
     * https://app.amplitude.com/data/ynab/Production/events/main/latest/Clicked%20Big%20Box%20Link
     */
    [AnalyticsEvents.Transaction_ClickedBigBoxLink]: {
        [AnalyticsProperties.DestinationUrl]: AnalyticsDestinationUrlValues;
    };
    /**
     * Triggered when a user enters the split transaction flow (by
     * clicking “Split” in the Category sheet).
     * https://app.amplitude.com/data/ynab/Production/events/main/latest/Clicked%20Split%20Transaction
     */
    [AnalyticsEvents.Transaction_ClickedSplitTransaction]: {
        [AnalyticsProperties.Location]: AnalyticsLocationValues;
    };
    /**
     * Set when a user changes the name of a flag/flags
     * https://app.amplitude.com/data/ynab/Production/events/main/latest/Customized%20Flag%20Names
     */
    [AnalyticsEvents.Transaction_CustomizedFlagNames]: {
        [AnalyticsProperties.FlagsWithCustomNames]: AnalyticsFlagsWithCustomNamesValues;
    };
    /**
     * Evergreen: Triggered when a transaction is deleted through the
     * popover or bulk edit menu.   Mobile: Triggered when a transaction
     * is deleted through the transaction detail view or bulk edit.   iOS
     * only: Also triggered when a transaction is deleted through the
     * transaction row context menu.
     * https://app.amplitude.com/data/ynab/Production/events/main/latest/Deleted%20Transaction
     */
    [AnalyticsEvents.Transaction_DeletedTransaction]: {
        [AnalyticsProperties.DeletedTransactionMethod]: AnalyticsDeletedTransactionMethodValues;
    };
    /**
     * Evergreen: Triggered when a transaction is matched through the
     * popover menu or bulk edit.   Mobile: Triggered when a transaction
     * is matched through the transaction details view or bulk edit menu.
     * iOS only: Also triggered when a transaction is matched through the
     * transaction row context menu.
     * https://app.amplitude.com/data/ynab/Production/events/main/latest/Matched%20Transaction
     */
    [AnalyticsEvents.Transaction_MatchedTransaction]: {
        [AnalyticsProperties.MatchedTransactionMethod]: AnalyticsMatchedTransactionMethodValues;
    };
    /**
     * Evergreen: Triggered when a transaction is rejected through the
     * popover or bulk edit menu.   Mobile: Triggered when a transaction
     * is rejected through the transaction detail view or bulk edit.   iOS
     * only: Also triggered when a transaction is rejected through the
     * transaction row context menu.
     * https://app.amplitude.com/data/ynab/Production/events/main/latest/Rejected%20Transaction
     */
    [AnalyticsEvents.Transaction_RejectedTransaction]: {
        [AnalyticsProperties.RejectedTransactionMethod]: AnalyticsRejectedTransactionMethodValues;
        [AnalyticsProperties.TransactionContextMenuSelection]: AnalyticsTransactionContextMenuSelectionValues;
    };
    /**
     * Triggered (with a 3 second debounce) when a user begins typing in
     * the search bar on either the Category Sheet or the Category
     * Selection Index (“Transaction Details View”).
     * https://app.amplitude.com/data/ynab/Production/events/main/latest/Searched%20Category
     */
    [AnalyticsEvents.Transaction_SearchedCategory]: {
        [AnalyticsProperties.Location]: AnalyticsLocationValues;
    };
    /**
     * Triggered whenever the account is set or edited on a transaction.
     * https://app.amplitude.com/data/ynab/Production/events/main/latest/Set%20Account
     */
    [AnalyticsEvents.Transaction_SetAccount]: {
        [AnalyticsProperties.SetAccountMethod]: AnalyticsSetAccountMethodValues;
    };
    /**
     * Triggered whenever the flag is set or edited on a transaction.
     * https://app.amplitude.com/data/ynab/Production/events/main/latest/Set%20Flag
     */
    [AnalyticsEvents.Transaction_SetFlag]: {
        [AnalyticsProperties.CustomFlagName]: AnalyticsCustomFlagNameValues;
        [AnalyticsProperties.SetFlagMethod]: AnalyticsSetFlagMethodValues;
    };
    /**
     * Evergreen: Triggered when a transaction is unmatched through the
     * popover menu or bulk edit.   Mobile: Triggered when a transaction
     * is unmatched through the transaction details view or bulk edit
     * menu.   iOS only: Also triggered when a transaction is unmatched
     * through the transaction row context menu.
     * https://app.amplitude.com/data/ynab/Production/events/main/latest/Unmatched%20Transaction
     */
    [AnalyticsEvents.Transaction_UnmatchedTransaction]: {
        [AnalyticsProperties.UnmatchedTransactionMethod]: AnalyticsUnmatchedTransactionMethodValues;
    };
    /**
     * Evergreen: Triggered through edit menu.   Mobile: Triggered through
     * transaction details view and by transaction row context menu.   A
     * note about bulk entry: Originally, we fired one event for each
     * pending/scheduled transaction being entered. In November 2021, this
     * was updated to fire only one event per user interaction, along with
     * the transaction count.
     * https://app.amplitude.com/data/ynab/Production/events/main/latest/Used%20Enter%20Now
     */
    [AnalyticsEvents.Transaction_UsedEnterNow]: {
        [AnalyticsProperties.TransactionCount]: AnalyticsTransactionCountValues;
        [AnalyticsProperties.TransactionState]: AnalyticsTransactionStateValues;
        [AnalyticsProperties.TransactionsType]: AnalyticsTransactionsTypeValues;
        [AnalyticsProperties.UsedEnterNowMethod]: AnalyticsUsedEnterNowMethodValues;
    };
};
